import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-collateral-types-page',
  templateUrl: './collateral-types-page.component.html',
  styleUrls: ['./collateral-types-page.component.css']
})
export class CollateralTypesPageComponent implements OnInit {
  waiting = false;
  submitted = false;
  hasError = false;
  noPartnerID = false;
  noUserID = false;
  displayInvalidForm = false;
  needSubtype = false;

  @ViewChild('form') form: NgForm;

  formData = {CollateralTypes: [], DealerGuid: "", UserID: ""};

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.route.queryParamMap
    .subscribe((x: any) => {
      // ensuring partnerid is available and is a valid GUID
      if (typeof x.params.partnerid != 'undefined' && this.isGUID(x.params.partnerid)) {
        this.formData.DealerGuid = x.params.partnerid;
      } else {
        this.hasError = true;
        this.noPartnerID = true;
      }
      if (typeof x.params.userid != 'undefined' && Number(x.params.userid) > 0) {
        this.formData.UserID = x.params.userid;
      } else {
        this.hasError = true;
        this.noUserID = true;
      }
    });
  }

  ngOnInit(): void {
  }
  
  setClasses(event, form) {
    let myClasses = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClasses;
  }
  updateCollateralTypesArray(e) {
    this.formData.CollateralTypes = e;
  }

  updateInvalidForm(e) {
    this.displayInvalidForm = e;
  }

  updateNeedSubtype(e) {
    this.needSubtype = e;
  }
  
  onSubmit(event) {
    this.waiting = true;
    // Ensuring form contains data
    if (!!this.formData.CollateralTypes.length && !this.displayInvalidForm && !this.needSubtype) {
      this.http
      .post('https://ds.p1fs.com/Dealer/Info/CollateralTypes', this.formData).pipe(timeout(10000))
      .subscribe({next: (data: any) => {
          if (!data.Error) {
            this.waiting = false;
            this.submitted = true;
          } else {
            this.hasError = true;
          }
      }, error: () => this.hasError = true });
      // if no data, display invalid form message
    } else {
      this.displayInvalidForm = true;
      this.waiting = false;
    }
  }
  // clears form and allows user to resubmit
  tryAgain() {
    this.hasError = false;
    this.waiting = false;
    this.formData.CollateralTypes.splice(0);
  }
  // checking if GUID
  isGUID(partnerID: any): boolean {
    const expr = /^\s*{?([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})}?\s*$/i;
    return expr.test(partnerID);
  }
}