import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CollateralTypesPageComponent } from './collateral-types-page/collateral-types-page.component';
import { DealerSignupConfirmationComponent } from './dealer-signup-confirmation/dealer-signup-confirmation.component';
import { DealerSignupComponent } from './dealer-signup/dealer-signup.component';
import { EqfComponent } from './eqf/eqf.component';
import { ErrorComponent } from './error/error.component';
import { FrccComponent } from './frcc/frcc.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { P1Component } from './p1/p1.component';
import { VersionHistoryComponent } from './version-history/version-history.component';

const routes: Routes = [
  { path: 'confirmation', component: DealerSignupConfirmationComponent },
  { path: '', component: DealerSignupComponent },
  { path: 'new-dealer-info/frcc', component: FrccComponent },
  { path: 'new-dealer-info/eqf', component: EqfComponent },
  { path: 'new-dealer-info/p1', component: P1Component },
  { path: 'dealer-info/collateral-types', component: CollateralTypesPageComponent },
  { path: 'versions', component: VersionHistoryComponent},
  { path: 'error', component: ErrorComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
