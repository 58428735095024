import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeChar',
})
export class RemoveCharPipe implements PipeTransform {
  // transform(str, target) {
  //   console.log(str)
  //   console.log(target)
  //   return (
  //     target.length > 0 && target === str.substr(str.length - target.length)
  //   );
  // }
  transform(str, charPosition) {
    let part1 = str.substring(0, charPosition);
    let part2 = str.substring(charPosition + 1, str.length);
    return part1 + part2;
  }
}
