import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import {
  ControlContainer,
  NgForm
} from '@angular/forms';
import { ConfigService } from '@app/service/config.service';

@Component({
  selector: 'collateral-types',
  templateUrl: './collateral-types.component.html',
  styleUrls: ['./collateral-types.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class CollateralTypesComponent implements OnInit {
  @Input('form') form: any = {};
  @Output() collateralTypesArrayChange = new EventEmitter<any[]>();
  @Output() invalidFormChange = new EventEmitter<boolean>();
  @Output() needSubtypeChange = new EventEmitter<boolean>();
  
  hideRVSubType: boolean = true;
  hidePowerSportsSubType: boolean = true;
  hideHorseTrailerSubType: boolean = true;

  p1Products = this.config.get('p1Products');
  rvProducts = this.config.get('rvProducts');
  powerSportsProducts = this.config.get('powerSportsProducts');
  horseTrailerProducts = this.config.get('horseTrailerProducts');

  needSubtype = false;
  collateralTypesArr = [];
  invalidForm = false;

  constructor(private config: ConfigService) {}

  ngOnInit(): void {
  }

  updateCollateralTypesArray() {
    this.collateralTypesArrayChange.emit(this.collateralTypesArr);
  }

  updateInvalidForm() {
    this.invalidFormChange.emit(this.invalidForm);
  }
  
  updateNeedSubtype() {
    this.needSubtypeChange.emit(this.needSubtype);
  }

  setClasses(event, form) {
    let myClasses = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClasses;
  }
  
  // On toggle, if we are hiding the options we also set them to an empty string.
  toggleShowRVSubType(event) {
    const { management } = this.form.controls;

    this.hideRVSubType = !this.hideRVSubType;
    if (this.hideRVSubType) {
      this.rvProducts.forEach(x => {
        management.controls['subType' + x].setValue("");
      });
      this.setType('RV', false);
    } else {
      this.setType('RV', true);
    }
  }
  toggleShowPowerSportsSubType(event) {
    const { management } = this.form.controls;

    this.hidePowerSportsSubType = !this.hidePowerSportsSubType;
    if (this.hidePowerSportsSubType) {
      this.powerSportsProducts.forEach(x => {
        management.controls['subType' + x].setValue("");
      });
      this.setType('PowerSports', false);
    } else {
      this.setType('PowerSports', true);
    }
  }
  toggleShowHorseTrailerSubType(event) {
    const { management } = this.form.controls;

    this.hideHorseTrailerSubType = !this.hideHorseTrailerSubType;
    if (this.hideHorseTrailerSubType) {
      this.horseTrailerProducts.forEach(x => {
        management.controls['subType' + x].setValue("");
      });
      this.setType('Horse Trailer', false);
    } else {
      this.setType('Horse Trailer', true);
    }
  }
  // checks the checkbox status, and pushes a new object with the passed collateral type
  setType(type, checked) {
    if (checked) {
      this.collateralTypesArr.push({ Name: type, SubTypes: []});
      // if unchecked, searches for collateral type and removes it from the array
    } else {
      var i = this.collateralTypesArr.findIndex(o => { return o.Name === type});
      if (i > -1) { 
        this.collateralTypesArr.splice(i, 1); 
      }
    }
    this.checkValidity();
    this.needSubtype = this.checkSubTypeValidity();   
    this.emitValues();
    // console.log(this.form);
  }
  // adds the subtype to the associated type, if checked.
  setSubtype(type, subType, checked) {
    var i = this.collateralTypesArr.findIndex(o => { return o.Name === type});
    if (checked) {
      this.collateralTypesArr[i]?.SubTypes.push(subType);
      // finds and removes subtype from parent array
    } else {
      var j = this.collateralTypesArr[i]?.SubTypes.findIndex(o => { return o === subType });
      this.collateralTypesArr[i].SubTypes.splice(j, 1);
    }
    this.needSubtype = this.checkSubTypeValidity();    
    this.emitValues();
  }

  // Return valid or invalid to parent
  checkValidity() {
    if (!!this.collateralTypesArr.length) {
      this.invalidForm = false;
    } else {
      this.invalidForm = true;
    }
    this.updateInvalidForm();
  }
  checkSubTypeValidity() {
    var rvIndex = this.collateralTypesArr.findIndex(o => { return o.Name === 'RV'});
    var powerSportsIndex = this.collateralTypesArr.findIndex(o => { return o.Name === 'PowerSports'});
    var horseTrailerIndex = this.collateralTypesArr.findIndex(o => { return o.Name === 'Horse Trailer'});
    if ((rvIndex > -1 && !(!!this.collateralTypesArr[rvIndex].SubTypes.length)) 
      || (powerSportsIndex > -1 && !(!!this.collateralTypesArr[powerSportsIndex].SubTypes.length) )
      || (horseTrailerIndex > -1 && !(!!this.collateralTypesArr[horseTrailerIndex].SubTypes.length))) {
      return true;
    } else return false;
  }

  emitValues() {
    this.updateCollateralTypesArray();
    this.updateNeedSubtype();
  }
}
