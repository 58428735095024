<h1 class="display-4">Additional Fees</h1>
<div ngModelGroup="additionalFees" class="mb-4">
  <div *ngFor="let name of model; let index = index">
    <hr class="w-100 mb-4" *ngIf="index >= 1" />
    <h4 class="lead">Fee {{ index + 1 }}</h4>
    <div [ngModelGroup]="name">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(feeName, form)"
              #feeName="ngModel"
              [name]="'fee' + (index + 1) + 'name'"
              maxlength="40"
              required
            />
            <small
              class="text-danger"
              *ngIf="feeName.invalid && (feeName.touched || form.submitted)"
              >Name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Amount in Dollars</label>
            <div class="placeholder" data-placeholder="$">
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(feeAmount, form)"
                #feeAmount="ngModel"
                [name]="'fee' + (index + 1) + 'amount'"
                maxlength="100"
                maxlength="20"
                mask="separator.2"
                thousandSeparator=","
                required
              />
            </div>
            <small
              class="text-danger"
              *ngIf="feeAmount.invalid && (feeAmount.touched || form.submitted)"
              >A valid number between 0-100 is required.</small
            >
          </div>
        </div>
      </div>
      <div class="col-12 text-right" *ngIf="model.length > 0">
        <button
          class="btn btn-danger btn-sm"
          type="button"
          (click)="removeItem(name)"
        >
          Remove Fee
        </button>
      </div>
    </div>
  </div>
</div>
<div class="text-center mb-4" *ngIf="model.length != 3">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    #addFee
    (click)="addItem()"
  >
    Add More Fees
  </button>
</div>
