import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascaltotitlecase'
})
export class PascalToTitleCase implements PipeTransform {

    transform(str: string): string {
      if (str !== str.toUpperCase()) {
        str = str.replace(/([A-Z])/g, " $1").replace(/^./, function(x){ return x.toUpperCase().trim(); });
      }
      return str;
    }

}
