import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidatorFn,
  Validators,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputFormComponent,
    },
  ],
})
export class InputFormComponent
  implements ControlValueAccessor, Validator, OnInit {
  @ViewChild('input') input: ElementRef;
  disabled;

  @Input() label: string;
  @Input() errMsg: string;
  @Input() placeholder: string = '';
  @Input() width: string;
  @Input() name: string;
  @Input() type: string = 'text';
  @Input() isRequired: boolean = false;
  @Input() pattern: string = null;

  constructor() {
    // this.controlDir.valueAccessor = this;
  }

  ngOnInit() {
    // const control = this.controlDir.control;
    // const validators: ValidatorFn[] = control.validator
    //   ? [control.validator]
    //   : [];
    // if (this.isRequired) {
    //   validators.push(Validators.required);
    // }
    // if (this.pattern) {
    //   validators.push(Validators.pattern(this.pattern));
    // }
    // control.setValidators(validators);
    // control.updateValueAndValidity();
  }

  onChange(event) {}

  onTouched() {}

  writeValue(obj: any): void {
    this.input.nativeElement.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    return validators;
  }
}
