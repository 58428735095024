import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'p1-toggle',
  templateUrl: './p1-toggle.component.html',
  styleUrls: ['./p1-toggle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: P1ToggleComponent,
    multi: true
  }]
})
export class P1ToggleComponent implements OnInit, ControlValueAccessor {
  @Input() labelMode?: 'checkAndX' | 'thumbsUpAndDown' | 'moonAndSun' | 'smileys' | 'string' | 'iconClass' | 'yesAndNo' = 'checkAndX';
  @Input() trueLabel:string = 'Yes';
  @Input() falseLabel:string = 'No';
  @Input() trueClass:string = 'bg-success';
  @Input() falseClass:string = 'bg-danger';
  @Input() className:string = '';
   
  icons = {
    checkAndX: {
      'true': 'fas fa-fw fa-check',
      'false': 'fas fa-fw fa-times'
    },
    thumbsUpAndDown: {
      'true': 'fa-solid fa-fw fa-thumbs-up',
      'false': 'fa-solid fa-fw fa-thumbs-down'
    },
    moonAndSun: {
      'true': 'fa-solid fa-fw fa-moon',
      'false': 'fa-solid fa-fw fa-sun'
    },
    smileys: {
      'true': 'fa-solid fa-fw fa-smile',
      'false': 'fa-solid fa-fw fa-frown'
    }
  }

  toggleValue:boolean;
  isDisabled:boolean = false;
  constructor() { }

  setValue(val: boolean) {
    this.toggleValue = val;
    console.log(val);
    this.onChange(val);
    this.onTouch();
  }
  writeValue(val: boolean): void {
    console.log(val);
    this.toggleValue = val;
  }
  onChange = (val: boolean) => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  onTouch = () => {};
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
  }
  

}
