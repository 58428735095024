<div class="card">
  <div class="card-body text-center">
    <h1 class="display-4 mb-4">
      <i class="fas fa-exclamation-triangle text-warning mr-3"></i
      >{{ statusText || "There was an issue with your request." }}
    </h1>
    <p class="lead">
      Please contact your Dealer Account Executive to help you with the next
      steps.
    </p>
  </div>
</div>
