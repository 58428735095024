import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'app';
  removeWrapper = false;

  constructor(private route: Router) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-8YPQ2PGTG2', {
          page_path: event.urlAfterRedirects,
        });
        this.removeWrapper = this.route.url.includes('/dealer-info/collateral-types');
      }
    });    
  }
}
