import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
 
  constructor(private http: HttpClient) {
  }

  private data: any;
  public get(name) {
    return this.data[name];
  }
  
  init() {
    return this.http.get("assets/data/data.json").pipe(
      tap(x => {
      this.data = x;
    })).toPromise();
  }

  public getCollateralGrouping() {
    return this.http.get(`https://ds.p1fs.com//PublicAppSettings/Global/CollateralGroups`);
  }
} 