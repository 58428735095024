<div class="card">
  <div class="card-body">
    <h1 class="display-4">Your Business</h1>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit(form)">
      <!-- Your Business Section Start -->
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Dealer Account Executive</label>
            <select
              class="custom-select"
              ngModel
              name="dae"
              [ngClass]="setClasses(dae, form)"
              #dae="ngModel"
            >
            <option></option>
              <option *ngFor="let value of names" [ngValue]="value">
                {{ value.name }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="dae.invalid && (dae.touched || form.submitted)"
                >Dealer account executive required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Lead Source</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(leadSource, form)"
              #leadSource="ngModel"
              name="leadSource"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                leadSource.invalid && (leadSource.touched || form.submitted)
              "
              >Lead source required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Full Legal Business Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessName, form)"
              #businessName="ngModel"
              name="businessName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessName.invalid && (businessName.touched || form.submitted)
              "
              >Business name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>DBA Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(dbaName, form)"
              #dbaName="ngModel"
              name="dbaName"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="dbaName.invalid && (dbaName.touched || form.submitted)"
              >DBA name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessPhone, form)"
              #businessPhone="ngModel"
              name="businessPhone"
              required
              maxlength="14"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                businessPhone.invalid &&
                (businessPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Fax</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessFax, form)"
              #businessFax="ngModel"
              name="businessFax"
              required
              maxlength="14"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                businessFax.invalid && (businessFax.touched || form.submitted)
              "
              >A valid 10 digit fax number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessEmail, form)"
              #businessEmail="ngModel"
              name="businessEmail"
              maxlength="40"
              required
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
            />
            <small
              class="text-danger"
              *ngIf="
                businessEmail.invalid &&
                (businessEmail.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessZip, form)"
              #businessZip="ngModel"
              name="businessZip"
              required
              (change)="validateZip($event)"
              (change)="
                getAddress($event.target.value, 'businessCity', 'businessState')
              "
              maxlength="5"
              minlength="5"
            />
            <small
              class="text-danger"
              *ngIf="
                businessZip.invalid && (businessZip.touched || form.submitted)
              "
              >Zip required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessCity, form)"
              #businessCity="ngModel"
              name="businessCity"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessCity.invalid && (businessCity.touched || form.submitted)
              "
              >City required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label class="labelDropdown">State</label>
            <select
              class="custom-select"
              required
              ngModel
              name="businessState"
              [ngClass]="setClasses(businessState, form)"
              #businessState="ngModel"
            >
              <option
                *ngFor="let value of states$"
                [value]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  businessState.invalid &&
                  (businessState.touched || form.submitted)
                "
                >State required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessAddress, form)"
              #businessAddress="ngModel"
              name="businessAddress"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessAddress.invalid &&
                (businessAddress.touched || form.submitted)
              "
              >Physical address required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Website</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessWebsite, form)"
              #businessWebsite="ngModel"
              name="businessWebsite"
              maxlength="60"
            />
            <small
              class="text-danger"
              *ngIf="
                businessWebsite.invalid &&
                (businessWebsite.touched || form.submitted)
              "
              >Website required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Number of Locations</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessLocations, form)"
              #businessLocations="ngModel"
              name="businessLocations"
              maxlength="2"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="
                businessLocations.invalid &&
                (businessLocations.touched || form.submitted)
              "
              >A valid number between 0-99 is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Years of Management</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(managementYears, form)"
              #managementYears="ngModel"
              name="managementYears"
              required
              maxlength="2"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="
                managementYears.invalid &&
                (managementYears.touched || form.submitted)
              "
              >A valid number between 0-99 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Organization Type</label>
            <select
              class="custom-select"
              required
              ngModel
              name="organizationType"
              [ngClass]="setClasses(organizationType, form)"
              #organizationType="ngModel"
            >
              <option *ngFor="let value of orgType" [ngValue]="value">
                {{ value }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  organizationType.invalid &&
                  (organizationType.touched || form.submitted)
                "
                >Organization type required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Federal Tax ID (aka FEIN)</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(fedTaxId, form)"
              #fedTaxId="ngModel"
              name="fedTaxId"
              maxlength="10"
              (change)="validateFein($event)"
              pattern="^[0-9]\d?-\d{7}$"
            />
            <small
              class="text-danger"
              *ngIf="fedTaxId.invalid && (fedTaxId.touched || form.submitted)"
              >Nine digits required.</small
            >
          </div>
        </div>
        <div class="col-12 mb-3 lead text-center">
          What types of products do you sell?
        </div>
        <label
          class="form-check col-6 col-lg-3 col-md-3"
          *ngFor="let text of products; let checkboxIndex = index"
          style="padding-left: 2.5rem"
        >
          <input
            class="form-check-input"
            type="checkbox"
            ngModel
            [name]="'product' + checkboxIndex"
            #product="ngModel"
          />
          {{ text }}
          <span
            *ngIf="product.invalid && (product.touched || form.submitted)"
            class="text-danger ml-2"
          >
            Required.
          </span>
        </label>
      </div>
      <!-- Your Business End -->
      <!-- Principals Start -->
      <h1 class="display-4">Principals</h1>
      <h4 class="lead">Principal 1</h4>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>First Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(firstName, form)"
              #firstName="ngModel"
              name="firstName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="firstName.invalid && (firstName.touched || form.submitted)"
              >First name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Middle Name</label>
            <input
              class="form-control"
              type="text"
              [ngClass]="setClasses(middleName, form)"
              ngModel
              #middleName="ngModel"
              name="middleName"
              maxlength="40"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Last Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lastName, form)"
              #lastName="ngModel"
              name="lastName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="lastName.invalid && (lastName.touched || form.submitted)"
              >Last name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Title</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(title, form)"
              #title="ngModel"
              name="title"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="title.invalid && (title.touched || form.submitted)"
              >Title required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Cell Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(phoneNumber, form)"
              #phoneNumber="ngModel"
              name="phoneNumber"
              maxlength="14"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                phoneNumber.invalid && (phoneNumber.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(principalEmail, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #principalEmail="ngModel"
              name="principalEmail"
              maxlength="50"
            />
            <small
              class="text-danger"
              *ngIf="
                principalEmail.invalid &&
                (principalEmail.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
      </div>
      <h4 class="lead">Principal 2</h4>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>First Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(firstName2, form)"
              #firstName2="ngModel"
              name="firstName2"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                firstName2.invalid && (firstName2.touched || form.submitted)
              "
              >First name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Middle Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(middleName2, form)"
              #middleName2="ngModel"
              name="middleName2"
              maxlength="40"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Last Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lastName2, form)"
              #lastName2="ngModel"
              name="lastName2"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="lastName2.invalid && (lastName2.touched || form.submitted)"
              >Last name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Title</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(title2, form)"
              #title2="ngModel"
              name="title2"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="title2.invalid && (title2.touched || form.submitted)"
              >Title required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Cell Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(phoneNumber2, form)"
              #phoneNumber2="ngModel"
              name="phoneNumber2"
              maxlength="14"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                phoneNumber2.invalid && (phoneNumber2.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(principalEmail2, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #principalEmail2="ngModel"
              name="principalEmail2"
              maxlength="50"
            />
            <small
              class="text-danger"
              *ngIf="
                principalEmail2.invalid &&
                (principalEmail2.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
      </div>
      <h4 class="lead">Principal 3</h4>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>First Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(firstName3, form)"
              [class.is-invalid]="
                firstName3.invalid && (firstName3.touched || form.submitted)
              "
              #firstName3="ngModel"
              name="firstName3"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                firstName3.invalid && (firstName3.touched || form.submitted)
              "
              >First name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Middle Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(middleName3, form)"
              #middleName3="ngModel"
              name="middleName3"
              maxlength="40"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Last Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lastName3, form)"
              [class.is-invalid]="
                lastName3.invalid && (lastName3.touched || form.submitted)
              "
              #lastName3="ngModel"
              name="lastName3"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="lastName3.invalid && (lastName3.touched || form.submitted)"
              >Last name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Title</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(title3, form)"
              #title3="ngModel"
              name="title3"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="title3.invalid && (title3.touched || form.submitted)"
              >Title required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Cell Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(phoneNumber3, form)"
              #phoneNumber3="ngModel"
              name="phoneNumber3"
              maxlength="14"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                phoneNumber3.invalid && (phoneNumber3.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(principalEmail3, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #principalEmail3="ngModel"
              name="principalEmail3"
              maxlength="50"
            />
            <small
              class="text-danger"
              *ngIf="
                principalEmail3.invalid &&
                (principalEmail3.touched || form.submitted)
              "
              >Email Address required.</small
            >
          </div>
        </div>
      </div>
      <!-- End of Princpials -->
      <!-- Start of Bank Deposit -->
      <h1 class="display-4">Bank Information</h1>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Bank Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankName, form)"
              #bankName="ngModel"
              name="bankName"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="bankName.invalid && (bankName.touched || form.submitted)"
              >Bank name required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Account Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountNumber, form)"
              #accountNumber="ngModel"
              name="accountNumber"
              maxlength="20"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="
                accountNumber.invalid &&
                (accountNumber.touched || form.submitted)
              "
              >Account number required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Bank Routing Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountRouting, form)"
              #accountRouting="ngModel"
              name="accountRouting"
              maxlength="9"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="
                accountRouting.invalid &&
                (accountRouting.touched || form.submitted)
              "
              >Routing number required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankZip, form)"
              (change)="validateZip($event)"
              (change)="
                getAddress($event.target.value, 'bankCity', 'bankState')
              "
              #bankZip="ngModel"
              name="bankZip"
              maxlength="5"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="bankZip.invalid && (bankZip.touched || form.submitted)"
              >Bank zip required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankCity, form)"
              #bankCity="ngModel"
              name="bankCity"
            />
            <small
              class="text-danger"
              *ngIf="bankCity.invalid && (bankCity.touched || form.submitted)"
              >Bank city required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label class="labelDropdown">State</label>
            <select
              class="custom-select"
              ngModel
              name="bankState"
              [ngClass]="setClasses(bankState, form)"
              #bankState="ngModel"
            >
              <option
                *ngFor="let value of states$"
                [ngValue]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  bankState.invalid && (bankState.touched || form.submitted)
                "
                >State required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankAddress, form)"
              #bankAddress="ngModel"
              name="bankAddress"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                bankAddress.invalid && (bankAddress.touched || form.submitted)
              "
              >Bank address required.</small
            >
          </div>
        </div>
      </div>
      <!-- End of Bank Deposit -->
      <!-- Start of Sale Info -->
      <h1 class="display-4">Sale Information</h1>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Annual Unit Sales</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(annualSales, form)"
              #annualSales="ngModel"
              name="annualSales"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="
                annualSales.invalid && (annualSales.touched || form.submitted)
              "
              >Annual unit sales required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Average Unit Size</label>
            <div class="placeholder" data-placeholder="$">
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(unitSize, form)"
              #unitSize="ngModel"
              name="unitSize"
              pattern="[0-9]+"
            />
          </div>
            <small
              class="text-danger"
              *ngIf="unitSize.invalid && (unitSize.touched || form.submitted)"
              >Average Unit Size required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Financed</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(financed, form)"
              #financed="ngModel"
              name="financed"
              max="100"
              min="0"
              maxlength="5"
              (change)="validateNumbers($event)"
              pattern="^(\d(\.\d*)?|\d\d(\.\d*)?|100)$"
            />
            <small
              class="text-danger"
              *ngIf="financed.invalid && (financed.touched || form.submitted)"
              >A valid number between 0 - 100 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Municipal</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(municipal, form)"
              #municipal="ngModel"
              name="municipal"
              max="100"
              min="0"
              maxlength="5"
              (change)="validateNumbers($event)"
              pattern="^(\d(\.\d*)?|\d\d(\.\d*)?|100)$"
            />
            <small
              class="text-danger"
              *ngIf="municipal.invalid && (municipal.touched || form.submitted)"
              >A valid number between 0 - 100 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Commercial</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(commercial, form)"
              #commercial="ngModel"
              name="commercial"
              max="100"
              min="0"
              maxlength="5"
              (change)="validateNumbers($event)"
              pattern="^(\d(\.\d*)?|\d\d(\.\d*)?|100)$"
            />
            <small
              class="text-danger"
              *ngIf="
                commercial.invalid && (commercial.touched || form.submitted)
              "
              >A valid number between 0 - 100 is required.</small
            >
          </div>
        </div>
      </div>
      <!-- End of Sale Info -->
      <!-- Start of Contact Info -->
      <h1 class="display-4">Contact Information</h1>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Accounting Contact</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountingContact, form)"
              #accountingContact="ngModel"
              name="accountingContact"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                accountingContact.invalid &&
                (accountingContact.touched || form.submitted)
              "
              >Accounting contact required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountingEmail, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #accountingEmail="ngModel"
              name="accountingEmail"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                accountingEmail.invalid &&
                (accountingEmail.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountingPhone, form)"
              #accountingPhone="ngModel"
              name="accountingPhone"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              maxlength="14"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                accountingPhone.invalid &&
                (accountingPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Titling/Registration Contact</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(titlingContact, form)"
              #titlingContact="ngModel"
              name="titlingContact"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                titlingContact.invalid &&
                (titlingContact.touched || form.submitted)
              "
              >Registration contact required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(titlingEmail, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #titlingEmail="ngModel"
              name="titlingEmail"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                titlingEmail.invalid && (titlingEmail.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(titlingPhone, form)"
              #titlingPhone="ngModel"
              name="titlingPhone"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              maxlength="14"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                titlingPhone.invalid && (titlingPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Salesperson</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(salespersonContact, form)"
              #salespersonContact="ngModel"
              name="salespersonContact"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                salespersonContact.invalid &&
                (salespersonContact.touched || form.submitted)
              "
              >Salesperson contact required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(salespersonEmail, form)"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              #salespersonEmail="ngModel"
              name="salespersonEmail"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                salespersonEmail.invalid &&
                (salespersonEmail.touched || form.submitted)
              "
              >Email address required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(salespersonPhone, form)"
              #salespersonPhone="ngModel"
              name="salespersonPhone"
              pattern="\(\d{3}\) \d{3}-\d{4}"
              maxlength="14"
              (change)="validatePhoneNumber($event)"
            />
            <small
              class="text-danger"
              *ngIf="
                salespersonPhone.invalid &&
                (salespersonPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
      </div>
      <!-- End of Contact Info -->
      <div class="row">
        <div class="col-12 col-lg-8">
          <span>
            Who at dealership is authorized to negotiate dealer finance income
            (points)?
          </span>
          <div class="form-group">
            <label>Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(pointsPerson, form)"
              #pointsPerson="ngModel"
              name="pointsPerson"
            />
            <small
              class="text-danger"
              *ngIf="
                pointsPerson.invalid && (pointsPerson.touched || form.submitted)
              "
              >A name is required.</small
            >
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label class="form-check-label col-12 m-4">
          <input
            class="form-check-input"
            type="checkbox"
            ngModel
            name="qualifiedDealership"
            #qualifiedDealership="ngModel"
          />
          Dealer may be eligible to offer guaranteed residual. I am interested
          in seeing if my dealership qualifies</label
        >
        <span
          *ngIf="
            qualifiedDealership.invalid &&
            (qualifiedDealership.touched || form.submitted)
          "
          class="text-danger ml-2"
          >Required.</span
        >
      </div>
      <button
        type="submit"
        class="btn btn-success btn-lg"
        [style.background-color]="form.invalid ? 'gray' : '#5cb85c'"
        [style.border]="form.invalid ? 'gray' : '#5cb85c'"
      >
        Submit
      </button>
      <div class="bg-secondary" *ngIf="form.submitted && form.valid">
        <pre>{{ form.value | json }}</pre>
      </div>
    </form>
  </div>
</div>
