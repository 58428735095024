import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AddressLookupService } from '@app/service/address-lookup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'principals',
  templateUrl: './principals.component.html',
  styleUrls: ['./principals.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PrincipalsComponent
  implements ControlValueAccessor, Validators, OnInit {
  model = [];
  states$: any = [];
  @Input() form: any = {};
  @Input() pattern: string = null;
  isRequired: boolean = false;
  @ViewChild('addPrincipal') addPrincipal: ElementRef;
  percentCheck: boolean = false;
  constructor(
    @Self() public controlDir: NgControl,
    private modal: NgbModal,
    private appService: AddressLookupService
  ) {
    this.controlDir.valueAccessor = this;
  }
  ngOnInit() {
    setTimeout(() => {
      this.addPrincipal.nativeElement.click();
    }, 100);
    return this.appService
      .getStates()
      .subscribe((data) => (this.states$ = data));
  }
  getAddress(zipCode, groupFormName, cityControlName, stateControlName) {
    this.appService.getAddressInfo(zipCode).subscribe((data) => {
      if (data) {
        this.form.controls[groupFormName].controls[cityControlName].setValue(
          data.City
        );
        this.form.controls[groupFormName].controls[stateControlName].setValue(
          data.State
        );
        this.form.controls[groupFormName].controls[
          cityControlName
        ].markAsTouched();
        this.form.controls[groupFormName].controls[
          stateControlName
        ].markAsTouched();
      }
    });
  }
  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }
  onChange(event) {}
  onTouched() {}
  writeValue(obj: any): void {
    this.model = obj || [];
  }
  registerOnChange(fn: any): void {
    this.onChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    return validators;
  }
  addItem() {
    const lastKey = this?.model[this.model.length - 1];
    const lastNumber = +lastKey?.replace(/\D/g, '');
    if (this.model.length == 0) return this.model.push('principal1');
    if (this.form.controls['principal' + lastNumber]?.valid) {
      this.model.push('principal' + (lastNumber + 1));
    } else {
      this.form.controls['principal' + lastNumber]?.markAllAsTouched();
    }
  }
  removeItem(controlName) {
    const matchIndex = this.model.indexOf(controlName);
    if (matchIndex > -1) this.model.splice(matchIndex, 1);
  }
  toolTipModal(content) {
    this.modal.open(content, { centered: true });
  }
  trackPercent() {
    let total = 0;
    let principalArr = [
      this.form.controls.principal1?.controls.ownershipPercent.value,
      this.form.controls.principal2?.controls.ownershipPercent.value,
      this.form.controls.principal3?.controls.ownershipPercent.value,
      this.form.controls.principal4?.controls.ownershipPercent.value,
      this.form.controls.principal5?.controls.ownershipPercent.value,
    ];

    for (let i in principalArr) {
      if (principalArr[i]) total += parseInt(principalArr[i]);
    }
    if (total === 100) {
      return (this.percentCheck = true);
    }
    return (this.percentCheck = false);
  }
  setSmsOptOut(groupFormName, event) {
    if (!this.form.controls[groupFormName].controls['smsOptOut']) {
      this.addSmsOptOutControlToPrincipal(groupFormName);
    }
    // I recognized this seems really backwards, because it is. But here we are
    if (event == 'Cell - SMS Ok') {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(false);
    } else if(event == 'Cell - No SMS') {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(true);
    }
    else {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(null);
    }
  }

  addSmsOptOutControlToPrincipal(groupFormName) {
    const principalGroup = this.form.controls[groupFormName] as FormGroup;
    principalGroup.addControl('smsOptOut', new FormControl(''));
  }
}
