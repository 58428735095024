import { Component, Input } from '@angular/core';
import { Value } from './dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
})
export class DropdownComponent {
  @Input() values: Value[] = [];
  constructor() {}
}
