<div style="height: 800px;" *ngIf="!submitted && !hasError">
    <div class="col-12 lead text-center" style="margin-bottom: 2em;">
        <h1>What types of <span class="text-danger">NEW</span> products do you sell?</h1>
    </div>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit(form)">
        <collateral-types 
        class="mx-auto"
        [form]="form" 
        (collateralTypesArrayChange)="updateCollateralTypesArray($event)"
        (invalidFormChange)="updateInvalidForm($event)"
        (needSubtypeChange)="updateNeedSubtype($event)"></collateral-types>
        <div class="text-center mt-5" style="padding-bottom: 6rem;">
            <button
                type="submit"
                class="btn btn-success btn-lg"
                [style.background-color]="form.invalid ? 'gray' : '#5cb85c'"
                [style.border]="form.invalid ? 'gray' : '#5cb85c'"
                [disabled]="waiting || displayInvalidForm || needSubtype"
            >
                Submit<i class="far fa-spinner fa-pulse" *ngIf="waiting"></i>
            </button>
            <p *ngIf="displayInvalidForm" class="text-danger mt-3">Please select at least one type or subtype of collateral you sell.</p>
            <p *ngIf="needSubtype" class="text-danger mt-3">Please select at least one subtype of collateral you sell.</p>
        </div>
    </form>
</div>

<div *ngIf="submitted && !hasError" class="text-center">
    <h1>Success! Your collateral types have been received.</h1>
    <p>You can close this tab and return to the DRC now.</p>
</div>

<div *ngIf="hasError" class="text-center">
    <h1>Uh oh... There was a problem.</h1>
    <p *ngIf="!noPartnerID && !noUserID">Try again or contact Priority One.</p>
    <p *ngIf="noPartnerID || noUserID">Please contact Priority One.</p>
    <button class="btn btn-info btn-lg" (click)="tryAgain()" (keyup.enter)="tryAgain()" *ngIf="!noPartnerID && !noUserID">Try Again</button>
</div>