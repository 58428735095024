<footer>
  <p class="text-white" style="font-size: 14px">
    {{ appVersion }}
    © {{copyright}} Priority One Financial Services, Inc., a Division of Forest River
    Inc., a Berkshire Hathaway Company |
    <span (click)="open(content)" style="cursor: pointer">Privacy Policy</span>
  </p>
</footer>
<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title">Privacy Policy</h1>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div [innerHTML]="privacyPolicyHtml"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>
