import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NgControl,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ConfigService } from '@app/service/config.service';
//import { p1ManufacturerTypes } from '@app/data/data';

@Component({
  selector: 'manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ManufacturerComponent
  implements OnInit, ControlValueAccessor, Validators {
  model = [];
  collateralTypesObj: {[key: string]: string[]} = {'manufacturer1': []};

  @Input() form: any = {};
  @Input() pattern: string = null;
  isRequired: boolean = false;
  @ViewChild('addManufacturer') addManufacturer: ElementRef;
  // p1ManufacturerTypes = this.config.get('p1ManufacturerTypes');
  p1ManufacturerTypes: string[] = [];
  constructor(@Self() public controlDir: NgControl, private config: ConfigService) {
    this.controlDir.valueAccessor = this;
  }
  ngOnInit() {

    this.config.getCollateralGrouping().subscribe((x: any) => {
      const collateralTypeObj = x.Setting.Value;
      let collateralTypes = [];
      Object.keys(collateralTypeObj).forEach((collateralType: any) => {
        if (collateralTypeObj[collateralType].length == 0 ) {
          collateralTypes.push(collateralType);
        }
        else {
          collateralTypeObj[collateralType].forEach((subType: any) => {
            collateralTypes.push(subType);
          });
        }
      });
      collateralTypes.forEach(x => this.p1ManufacturerTypes.push(x));
    });

    setTimeout(() => {
      this.addManufacturer.nativeElement.click();
    }, 100);
  }
  
  camelToTitleCase(str: string) {
    if (str !== str.toUpperCase()) {
      str = str.replace(/([A-Z])/g, " $1").replace(/^./, function(x){ return x.toUpperCase().trim(); });
    }
    return str;
  }

  toggleCollateral(name: string, value: string, checked: boolean) {
    if (checked) this.collateralTypesObj[name].push(value);
    else this.collateralTypesObj[name].splice(this.collateralTypesObj[name].indexOf(value), 1);

    this.form.controls[name].setValue({...this.form.controls[name].value, collateralTypes: this.collateralTypesObj[name]});

    this.form.controls[name].controls.collateralTypes.markAsTouched();
  }

  checkDropdown(name: string) {
    if (this.form.controls[name]?.value.collateralTypes?.length == 0 && this.form?.controls[name]?.controls?.collateralTypes?.touched) {
      return 'invalid';
    } else if (this.form.controls[name]?.value.collateralTypes && this.form.controls[name]?.value.collateralTypes?.length > 0) return 'valid';
  }

  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }
  onChange(event) {}
  onTouched() {}
  writeValue(obj: any): void {
    this.model = obj || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    return validators;
  }
  addItem() {
    const lastKey = this?.model[this.model.length - 1];
    const lastNumber = +lastKey?.replace(/\D/g, '');
    if (this.model.length == 0) return this.model.push('manufacturer1');
    if (this.form.controls['manufacturer' + lastNumber]?.valid && this.form.controls['manufacturer' + lastNumber].value.collateralTypes.length > 0) {
      this.model.push('manufacturer' + (lastNumber + 1));
      this.collateralTypesObj['manufacturer' + (lastNumber + 1)] = [];
    } else {
      this.form.controls['manufacturer' + lastNumber]?.markAllAsTouched();
    }
  }
  removeItem(controlName) {
    const matchIndex = this.model.indexOf(controlName);
    if (matchIndex > -1) this.model.splice(matchIndex, 1);
    delete this.collateralTypesObj[controlName];
  }
}
