import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-signup-confirmation',
  templateUrl: './dealer-signup-confirmation.component.html',
  styleUrls: ['./dealer-signup-confirmation.component.css']
})
export class DealerSignupConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
