import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import {
//   daes,
//   orgType,
//   p1Products,
//   stateAbbreviations,
//   traderReferences,
//   p1WholeSaleItems,
//   p1TaxInformation,
//   p1ManufacturerTypes,
//   p1ServiceContracts,
//   p1ContactInfo,
//   licensesTypesTX,
//   licensesTypesPA,
//   p1AdditionalContacts,
// } from '../data/data'

import { AddressLookupService } from '@app/service/address-lookup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@app/service/config.service';
import { CollateralTypesComponent } from '@app/components/collateral-types/collateral-types.component';
import { FormControl, FormGroup } from '@angular/forms';
import { UnifiedMonitorService } from '@app/service/unified-monitor.service';


declare const gtag: Function;
declare const grecaptcha: any;

@Component({
  selector: 'app-eqf',
  templateUrl: './p1.component.html',
  styleUrls: ['./p1.component.css'],
})
export class P1Component implements OnInit {
  names = this.config.get("daes");
  orgType = this.config.get('orgType')
  p1Products = this.config.get('p1Products')
  rvProducts = this.config.get('rvProducts')
  powerSportsProducts = this.config.get('powerSportsProducts')
  horseTrailerProducts = this.config.get('horseTrailerProducts')
  stateAbbreviations = this.config.get('stateAbbreviations')
  licensesTypesTX = this.config.get('licensesTypesTX')
  licensesTypesPA = this.config.get('licensesTypesPA')
  states$: any = [];
  traderReferences = this.config.get('traderReferences')
  p1WholeSaleItems = this.config.get('p1WholeSaleItems')
  p1TaxInformation = this.config.get('p1TaxInformation')
  p1ManufacturerTypes = this.config.get('p1ManufacturerTypes')
  p1ServiceContracts = this.config.get('p1ServiceContracts')
  p1ContactInfo = this.config.get('p1ContactInfo')
  p1AdditionalContacts = this.config.get('p1AdditionalContacts')
  waiting = false;
  percentCheck: boolean;
  eitherFieldRequired: boolean = false;
  hideRVSubType: boolean = true;
  hidePowerSportsSubType: boolean = true;
  hideHorseTrailerSubType: boolean = true;
  invalidCollateralType: boolean = false;
  subtypesNeeded: boolean = false;

  public fieldNames: string[] = [];
  list: string[] = [
    'AFC',
    'Bank of America',
    'Bank of the West',
    'GE',
    'MMAC',
    'Northpoint',
    'NextGear',
    'TCF',
    'Textron',
    'Wells Fargo',
  ];
  titleArr: any[] = [
    'Accounting',
    'Accounting Manager',
    'Closing',
    'Controller',
    'General Manager',
    'Invoicing',
    'Invoicing Manager',
    'Manager',
    'Owner',
    'President',
    'Sales',
    'Sales Manager',
    'Secretary',
    'Titling',
    'Titling Manager',
  ];
  fiChoicesArr: any[] = [
    'Unknown',
    'In house',
    'Other service company',
    'Local sources',
    'Didn\'t offer financing',
    'Other'
  ];
  dmsArr: any[] = [
    'None',
    'BlackPearl',
    'DealerRock',
    'IDS',
    'Lightspeed',
    'Motility',
    'WinnBoats',
    'Other',
  ];
  filteredList: string[] = [];
  @ViewChild('form') form: any;
  @ViewChild(CollateralTypesComponent) colTypeComp: CollateralTypesComponent;

  constructor(
    private appService: AddressLookupService,
    private route: Router,
    private http: HttpClient,
    private modal: NgbModal,
    private config: ConfigService,
    private um: UnifiedMonitorService
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-25194069-10', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
  isCategoryAvailable(category) {
    let prevCatArr = [];
    let selectedCats = this.filteredList.map((x) => x);
    prevCatArr.push(selectedCats);
    for (let i = 0; i < prevCatArr.length; i++) {
      if (selectedCats == prevCatArr[i]) {
        return selectedCats.indexOf(category) == -1;
      } else {
        this.filteredList.push(prevCatArr[i]);
      }
    }
  }
  removeItem(index) {
    this.filteredList.splice(index, 1);
  }
  selectedItem(bankObj) {
    if (bankObj.value != '') {
      this.filteredList.push(bankObj.value);
      setTimeout(() => {
        if (bankObj.value != 'Other') {
          this.form.controls[bankObj.formGroupName].controls[
            bankObj.controlName
          ].setValue(bankObj.value);
          this.form.controls[bankObj.formGroupName].controls[
            bankObj.controlName
          ].markAsTouched();
        }
      }, 100);
    }
  }
  anyFormMembersTrue(groupFormName) {
    if (this.form && this.form.value && this.form.value[groupFormName]) {
      return (
        Object.keys(this.form?.value[groupFormName]).filter(
          (item) => this.form?.value[groupFormName][item]
        ).length > 0
      );
    }
    return false;
  }
  addNames() {
    if (this.fieldNames.length == 0) {
      this.fieldNames.push('additionalStaffMembers1');
      return true;
    }
    const lastKey = this.fieldNames[this.fieldNames.length - 1];
    const lastNumber = +lastKey.replace(/\D/g, '');
    if (this.form.controls['additionalStaffMembers' + lastNumber]?.valid) {
      this.fieldNames.push('additionalStaffMembers' + (lastNumber + 1));
      return true;
    } else {
      this.form.controls[
        'additionalStaffMembers' + lastNumber
      ]?.markAllAsTouched();
      return false;
    }
  }
  removeFields(name) {
    const matchIndex = this.fieldNames.indexOf(name);
    if (matchIndex > -1) this.fieldNames.splice(matchIndex, 1);
  }
  showValidation(event) {
    gtag('event', 'highlight_required_fields', {
      event_category: 'P1',
      event_label: event.checked ? 'on' : 'off',
    });
    if (event.checked) {
      this.form.form.markAllAsTouched();
      this.colTypeComp.checkValidity();
    } else if (!event.checked) {
      Object.keys(this.form.controls).forEach((key) => {
        if (key.includes('lenders') || key.includes('additionalFees')) {
          if (Object.keys(this.form.controls[key]?.controls).length !== 0) {
            Object.keys(this.form.controls[key]?.controls).forEach(
              (innerControlName) => {
                Object.keys(
                  this.form.controls[key].controls[innerControlName].controls
                ).forEach((deepKey) => {
                  this.form.controls[key].controls[innerControlName].controls[
                    deepKey
                  ].touched = false;
                });
              }
            );
          }
        }
        let innerKey = Object.keys(this.form.controls[key].controls);
        innerKey.map((data) => {
          this.form.controls[key].controls[data].touched = false;
        });
      });
    }
  }
  collateralTypeInvalid(e) {
    this.invalidCollateralType = e;
  }

  needCollateralSubtype(e) {
    this.subtypesNeeded = e;
  }

  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form?.submitted),
      invalid: event.invalid && (event.touched || form?.submitted),
    };
    return myClassess;
  }
  ngOnInit() {
    return this.appService
      .getStates()
      .subscribe((data) => (this.states$ = data));
  }
  getAddress(zipCode, groupFormName, cityControlName, stateControlName) {
    this.appService.getAddressInfo(zipCode).subscribe((data) => {
      if (data) {
        this.form.controls[groupFormName].controls[cityControlName].setValue(
          data.City
        );
        this.form.controls[groupFormName].controls[stateControlName].setValue(
          data.State
        );
        this.form.controls[groupFormName].controls[
          cityControlName
        ].markAsTouched();
        this.form.controls[groupFormName].controls[
          stateControlName
        ].markAsTouched();
      }
    });
  }

  toolTipModal(content) {
    this.modal.open(content, { centered: true });
  }

  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      'form .ng-invalid:not([ngmodelgroup])'
    );
    window.scroll({
      top: this.getTopOffset(firstElementWithError),
      left: 0,
      behavior: 'smooth',
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 80;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  changeKey(oldObject, oldKey, newKey) {
    let newWordsObject = {};
    Object.keys(oldObject).forEach((key) => {
      if (key === oldKey) {
        let newPair = { [newKey]: oldObject[oldKey] };
        newWordsObject = { ...newWordsObject, ...newPair };
      } else {
        newWordsObject = { ...newWordsObject, [key]: oldObject[key] };
      }
    });
    return newWordsObject;
  }
  cleanUpPhones(o) {
    let i = 0;
    for (i = 0; i < Object.keys(o).length; i++) {
      let outerKey = Object.keys(o)[i];
      let outerVal = o[outerKey];
      let j = 0;
      for (j = 0; j < Object.keys(outerVal).length; j++) {
        const key = Object.keys(outerVal)[j];
        let val = outerVal[key];
        let possibleMatch = /(.*)PhoneType$/gi.exec(key);
        if (possibleMatch) {
          if (val === 'Cell - SMS Ok' || val === 'Cell - No SMS') {
            o[outerKey]['phoneType'] = 'Cell Phone';
            val = 'Cell Phone';
          }

          if (val === 'Cell Phone') {
            let possibleKey = o[outerKey][possibleMatch[1] + 'Phone']
              ? possibleMatch[1] + 'Phone'
              : possibleMatch[1] + 'phone';
            o[outerKey] = this.changeKey(
              o[outerKey],
              possibleKey,
              possibleMatch[1] + 'CellPhone'
            );
          }
          delete o[outerKey][possibleMatch[1] + 'PhoneType'];
          delete o[outerKey][possibleMatch[1] + 'phoneType'];
        }
      }
    }
  }
  onSubmit(event) { // If subtypes or invalidCollat, make event.valid false. Needs to check constantly. Otherwise, this will work
    this.um.log('P1SubmitStarted', this.route.url, event.form.value);

    if (event.valid && !this.subtypesNeeded && !this.invalidCollateralType) {
      const { management } = event.form.controls;
      const { tradeReferences } = event.form.controls;
      const { wholesaleInfo } = event.form.controls;
      const { serviceContracts } = event.form.controls;
      const { licenses } = event.form.controls;
      const manufacturers = Object.keys(event.form.controls).filter((x: string) => {
        return x.startsWith('manufacturer');
      });

      manufacturers.forEach(x => {
        if (event.form.controls[x].value.collateralTypes) event.form.controls[x].setValue({...this.form.controls[x].value, collateralTypes: event.form.controls[x].value.collateralTypes.join(',')});
      });

      const keysArr: string[] = [];

      this.p1Products.forEach(x => {
        keysArr.push('productType' + x);
      });
      this.rvProducts.forEach(x => {
        keysArr.push('subType' + x);
      });
      this.powerSportsProducts.forEach(x => {
        keysArr.push('subType' + x);
      });
      this.horseTrailerProducts.forEach(x => {
        keysArr.push('subType' + x);
      })

      keysArr.map((data) => {
        if (!management.value[data]) {
          management.value[data] = 'No';
        } else management.value[data] = 'Yes';
      });

      Object.keys(tradeReferences.value).map((data) => {
        if (data === 'tradeReferenceOther') return;
        if (!tradeReferences.value[data]) {
          tradeReferences.value[data] = 'No';
        } else tradeReferences.value[data] = 'Yes';
      });

      Object.keys(serviceContracts.value).map((data) => {
        if (data === 'hourlyRepairLaborRate') return;
        if (data === 'extendedServiceContractOther') return;
        if (!serviceContracts.value[data]) {
          serviceContracts.value[data] = 'No';
        } else serviceContracts.value[data] = 'Yes';
      });
      if (this.form.value.wholesaleInfo) delete this.form.value.wholesaleInfo;
      if (this.form.value.wholesaleinfo2) delete this.form.value.wholesaleinfo2;

      if (licenses) {
        Object.keys(licenses.value).map((data) => {
          if (!licenses.value[data]) {
            licenses.value[data] = 'No';
          } else licenses.value[data] = 'Yes';
        });
      }
      Object.keys(event.value.additionalFees).forEach((key) => {
        if (
          typeof event.value.additionalFees[key] === 'object' &&
          event.value.additionalFees[key] !== null
        ) {
          Object.assign(
            event.value.additionalFees,
            event.value.additionalFees[key]
          );
          delete event.value.additionalFees[key];
        }
      });
      Object.keys(event.value.lenders).forEach((key) => {
        if (
          typeof event.value.lenders[key] === 'object' &&
          event.value.lenders[key] !== null
        ) {
          Object.assign(event.value.lenders, event.value.lenders[key]);
          delete event.value.lenders[key];
        }
      });
      this.cleanUpPhones(event.value);
      gtag('event', 'submit', {
        event_category: 'P1',
      });

      this.um.log('P1Submitting', this.route.url, event.form.value);

      this.waiting = true;
      grecaptcha.ready(() => {
        grecaptcha
          .execute('6LfmBdcZAAAAAMFHX0wTH_mnt4D86iucDJBIPbS9', {
            action: 'submitP1',
          })
          .then((token) => {
            let formSubmit = this.http.post(
              `https://ds.p1fs.com/dealer/info/p1/?recaptchatoken=${token}`,
              event.form.value
            );
            formSubmit.subscribe(
              (data: any) => {
                this.waiting = false;
                if (data.success) {
                  this.um.log('P1SubmitSuccess', this.route.url, {});
                  this.route.navigate(['confirmation']);
                } else {
                  this.um.log('P1SubmitError', this.route.url, {});
                  this.route.navigate(['error']);
                }
              },
              (err) => {
                this.um.log('P1SubmitError', this.route.url, {Error: err});
                this.route.navigate(['error', { statusCode: err.status }]);
              }
            );
          });
      });
    } else {
      this.scrollToError();
    }
  }
  setSmsOptOut(groupFormName, event) {
    if (!this.form.controls[groupFormName].controls['smsOptOut']) {
      this.addSmsOptOutControlToPrincipal(groupFormName);
    }
    // I recognized this seems really backwards, because it is. But here we are
    if (event == 'Cell - SMS Ok') {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(false);
    } else if(event == 'Cell - No SMS') {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(true);
    }
    else {
      this.form.controls[groupFormName].controls['smsOptOut'].setValue(null);
    }
  }

  addSmsOptOutControlToPrincipal(groupFormName) {
    const principalGroup = this.form.controls[groupFormName] as FormGroup;
    principalGroup.addControl('smsOptOut', new FormControl(''));
  }
}
