import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import {
//   eqfDae,
//   orgType,
//   eqfProducts,
//   stateAbbreviations,
//   contactInfo,
// } from '@app/data/data';
import { AddressLookupService } from '@app/service/address-lookup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@app/service/config.service';
import { FormControl, FormGroup } from '@angular/forms';
import { UnifiedMonitorService } from '@app/service/unified-monitor.service';

declare const grecaptcha: any;
declare const gtag: Function;

@Component({
  selector: 'app-eqf',
  templateUrl: './eqf.component.html',
  styleUrls: ['./eqf.component.css'],
})
export class EqfComponent implements OnInit {
  eqfDae = this.config.get('eqfDae');
  orgType = this.config.get('orgType');
  eqfProducts = this.config.get('eqfProducts');
  stateAbbreviations = this.config.get('stateAbbreviations');
  contactInfo = this.config.get('contactInfo');
  states$: any = [];
  percentArr = [];
  waiting = false;
  percentCheck: boolean;
  defaultValue = 'Landline';
  options = ['Landline', 'Cell Phone'];
  signerNameArr: Array<any> = [];
  isInvalid: boolean = false;
  selectedPrincipalModel;

  @ViewChild('form') form: any;
  @ViewChild('content') content: TemplateRef<any>;

  constructor(
    private appService: AddressLookupService,
    private route: Router,
    private http: HttpClient,
    private modal: NgbModal,
    private config: ConfigService,
    private um: UnifiedMonitorService
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-25194069-10', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit() {
    return this.appService
      .getStates()
      .subscribe((data) => (this.states$ = data));
  }

  getAddress(zipCode, groupFormName, cityControlName, stateControlName) {
    this.appService.getAddressInfo(zipCode).subscribe((data) => {
      if (data) {
        this.form.controls[groupFormName].controls[cityControlName].setValue(
          data.City
        );
        this.form.controls[groupFormName].controls[stateControlName].setValue(
          data.State
        );
        this.form.controls[groupFormName].controls[
          cityControlName
        ].markAsTouched();
        this.form.controls[groupFormName].controls[
          stateControlName
        ].markAsTouched();
      }

      let checkForCa = this.form.controls[groupFormName].controls[
        stateControlName
      ];

      if (checkForCa.value === 'CA') {
        return this.checkState(checkForCa, this.content);
      }
    });
  }

  showValidation(event) {
    gtag('event', 'highlight_required_fields', {
      event_category: 'EQF',
      event_label: event.checked ? 'on' : 'off',
    });
    if (event.checked) {
      Object.keys(this.form.controls).forEach((key) => {
        let innerKey = Object.keys(this.form.controls[key].controls);
        innerKey.map((data) => {
          return (this.form.controls[key].controls[data].touched = true);
        });
      });
    } else if (!event.checked) {
      Object.keys(this.form.controls).forEach((key) => {
        let innerKey = Object.keys(this.form.controls[key].controls);
        innerKey.map((data) => {
          return (this.form.controls[key].controls[data].touched = false);
        });
      });
    }
  }

  signatureTextChanged(signatureText) {
    this.isInvalid = !this.signaturesMatch(
      signatureText?.value,
      this.form?.controls?.signature?.value?.selectedPrincipal?.name
    );
  }

  signaturesMatch(sig1, sig2) {
    if (!sig1 || !sig2) return false;
    return this.cleanupSignature(sig1) === this.cleanupSignature(sig2);
  }

  cleanupSignature(sig) {
    return (sig || '').replace(/\s+/gi, ' ').trim().toLowerCase();
  }

  checkState(control, content) {
    if (control.value === 'CA') {
      this.modal.open(content, { centered: true });
    }
  }

  trackPercent() {
    let total = 0;
    let principalArr = [
      this.form.controls.principal1.controls.ownershipPercent.value,
      this.form.controls.principal2.controls.ownershipPercent.value,
      this.form.controls.principal3.controls.ownershipPercent.value,
    ];

    for (let i in principalArr) {
      if (principalArr[i]) total += parseInt(principalArr[i]);
    }
    if (total === 100) {
      return (this.percentCheck = true);
    }
    return (this.percentCheck = false);
  }

  toolTipModal(content) {
    this.modal.open(content, { centered: true });
  }

  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }

  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      'form .ng-invalid'
    );
    window.scroll({
      top: this.getTopOffset(firstElementWithError),
      left: 0,
      behavior: 'smooth',
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  changeKey(oldObject, oldKey, newKey) {
    let newWordsObject = {};
    Object.keys(oldObject).forEach((key) => {
      if (key === oldKey) {
        let newPair = { [newKey]: oldObject[oldKey] };
        newWordsObject = { ...newWordsObject, ...newPair };
      } else {
        newWordsObject = { ...newWordsObject, [key]: oldObject[key] };
      }
    });
    return newWordsObject;
  }

  cleanUpPhones(o) {
    let i = 0;
    for (i = 0; i < Object.keys(o).length; i++) {
      let outerKey = Object.keys(o)[i];
      let outerVal = o[outerKey];
      let j = 0;
      for (j = 0; j < Object.keys(outerVal).length; j++) {
        const key = Object.keys(outerVal)[j];
        let val = outerVal[key];
        let possibleMatch = /(.*)PhoneType$/gi.exec(key);
        if (possibleMatch) {
          if (val === 'Cell - SMS Ok' || val === 'Cell - No SMS') {
            o[outerKey]['phoneType'] = 'Cell Phone';
            val = 'Cell Phone';
          }

          if (val === 'Cell Phone') {
            let possibleKey = o[outerKey][possibleMatch[1] + 'Phone']
              ? possibleMatch[1] + 'Phone'
              : possibleMatch[1] + 'phone';
            o[outerKey] = this.changeKey(
              o[outerKey],
              possibleKey,
              possibleMatch[1] + 'CellPhone'
            );
          }
          delete o[outerKey][possibleMatch[1] + 'PhoneType'];
          delete o[outerKey][possibleMatch[1] + 'phoneType'];
        }
      }
    }
  }

  concatToFullName() {
    let { principal1 } = this.form.form.controls;
    let { principal2 } = this.form.form.controls;
    let { principal3 } = this.form.form.controls;
    this.signerNameArr = [
      {
        name: `${principal1.value.firstName} ${principal1.value.middleName} ${principal1.value.lastName}`,
        title: principal1.value.title,
      },
      {
        name: `${principal2.value.firstName} ${principal2.value.middleName} ${principal2.value.lastName}`,
        title: principal2.value.title,
      },
      {
        name: `${principal3.value.firstName} ${principal3.value.middleName} ${principal3.value.lastName}`,
        title: principal3.value.title,
      },
    ].filter((item) => (item.name || '').trim().length !== 0);

    if (this.signerNameArr.length === 1) {
      this.selectedPrincipalModel = this.signerNameArr[0];
      this.form.form.get('signature.selectedPrincipal')?.markAsTouched();
    }
  }

  onSubmit(event) {
    this.um.log('EQFSubmitStarted', this.route.url, event.form.value);
    if (event.valid) {
      const managementArr = [
        'productTypeOtherChecked',
        'productTypeRV',
        'productTypeCargo/Utility',
        'productTypeMarine',
        'productTypeBus',
        'productTypeTruck',
        'productTypeLimo',
      ];
      const { management } = this.form.form.controls;

      managementArr.map((data) => {
        if (!management.value[data]) {
          management.value[data] = 'No';
        } else management.value[data] = 'Yes';
      });

      this.cleanUpPhones(event.value);
      event.form.value.signature = {
        SignerRole: event.form.value.signature.selectedPrincipal.title,
        SignatureText: event.form.value.signature.signatureText,
        Source: 'NewDealerInfoEQF',
      };

      gtag('event', 'submit', {
        event_category: 'EQF',
      });

      this.um.log('EQFSubmitting', this.route.url, event.form.value);

      this.waiting = true;
      grecaptcha.ready(() => {
        grecaptcha
          .execute('6LfmBdcZAAAAAMFHX0wTH_mnt4D86iucDJBIPbS9', {
            action: 'submitEQF',
          })
          .then((token) => {
            let formSubmit = this.http.post(
              `https://ds.p1fs.com/dealer/info/eqf/?recaptchatoken=${token}`,
              // `http://localhost:64508/dealer/info/eqf/?recaptchatoken=${token}`,
              event.form.value
            );
            let signSubmit = this.http.post(
              'https://ds.p1fs.com/util/sign',
              event.form.value.signature
            );
            forkJoin([formSubmit, signSubmit]).subscribe(
              (data: any) => {
                this.waiting = false;
                if (data[0].success) {
                  this.um.log('EQFSubmitSuccess', this.route.url, {});
                  this.route.navigate(['confirmation']);
                } else {
                  this.um.log('EQFSubmitError', this.route.url, {});
                  this.route.navigate(['error']);
                }
              },
              (err) => {
                this.um.log('EQFSubmitError', this.route.url, {Error: err});
                this.route.navigate(['error', { statusCode: err.status }]);
              }
            );
          });
      });
    } else {
      this.scrollToError();
    }
  }

  setSmsOptOut(groupFormName, event, contactType = '') {
    const optOutControlName = (contactType + ' smsOptOut').trim();
    if (!this.form.controls[groupFormName].controls[optOutControlName]) {
      this.addSmsOptOutControlToPrincipal(groupFormName, optOutControlName);
    }
    // I recognized this seems really backwards, because it is. But here we are
    if (event == 'Cell - SMS Ok') {
      this.form.controls[groupFormName].controls[optOutControlName].setValue(false);
    } else if(event == 'Cell - No SMS') {
      this.form.controls[groupFormName].controls[optOutControlName].setValue(true);
    }
    else {
      this.form.controls[groupFormName].controls[optOutControlName].setValue(null);
    }
  }

  addSmsOptOutControlToPrincipal(groupFormName, optOutControlName) {
    const principalGroup = this.form.controls[groupFormName] as FormGroup;
    principalGroup.addControl(optOutControlName, new FormControl(''));
  }
}
