import { Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { daes, agreements } from '../data/data';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '@app/service/config.service';
import { UnifiedMonitorService } from '@app/service/unified-monitor.service';

declare const gtag: Function;

@Component({
  selector: 'app-dealer-signup',
  templateUrl: './dealer-signup.component.html',
  styleUrls: ['./dealer-signup.component.css'],
})
export class DealerSignupComponent {
  names = this.config.get('daes');
  p1Data = this.config.get('agreements')?.p1Responsibility;
  dealerData = this.config.get('agreements')?.dealerResponsibility;
  dealerLi = this.config.get('agreements')?.dealerContractTerms?.general;
  dealerListData = this.config.get('agreements')?.dealerContractTerms?.terms;
  disclosure = this.config.get('agreements')?.general;
  waiting = false;

  @ViewChild('dealerForm') dealerForm: any;

  constructor(
    private route: Router,
    private http: HttpClient,
    private modal: NgbModal,
    private config: ConfigService,
    private um: UnifiedMonitorService
  ) {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-25194069-10', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }

  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      'form .ng-invalid'
    );
    window.scroll({
      top: this.getTopOffset(firstElementWithError),
      left: 0,
      behavior: 'smooth',
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  open(content) {
    this.modal.open(content, { centered: true });
  }

  onSubmit(event) {
    this.um.log('DealerSignupSubmitStarted', this.route.url, event.form.value);

    if (event.valid) {
      gtag('event', 'submit', {
        event_category: 'dealer_agreement',
      });
      this.waiting = true;
      event.form.value.agreements = this.config.get('agreements') ;
      this.um.log('DealerSignupSubmitting', this.route.url, event.form.value);
      this.http
        .post('https://ds.p1fs.com/Dealer', event.form.value)
        .subscribe((data: any) => {
          this.waiting = false;
          if (data.success) {
            this.um.log('DealerSignupSubmitted', this.route.url, {});
            this.route.navigate(['confirmation']);
          } else {
            this.um.log('DealerSignupSubmitError', this.route.url, {});
            this.route.navigate(['error']);
          }
        });
    } else {
      this.scrollToError();
    }
  }
}
