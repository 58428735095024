import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  privacyEmail: string;
  copyright: any = new Date().getFullYear();
  appVersion: any = require('../../../../package.json').version;
  privacyPolicyHtml: SafeHtml;

  constructor(private modal: NgbModal, private httpClient: HttpClient, private sanitizer: DomSanitizer) {}
  
  open(content) {
    this.modal.open(content, { scrollable: true, size: "lg" });
  }
  
  ngOnInit(){
    let privacy = 'privacy';
    let at = '@';
    let priority = 'p1fs';
    let dotCom = '.com'
    this.privacyEmail = `${privacy}${at}${priority}${dotCom}`
    
    this.getPrivacyPolicy().subscribe((x: any) => {
      this.privacyPolicyHtml = x ? this.sanitizer.sanitize(SecurityContext.HTML, x) : x;
    });
  }

  getPrivacyPolicy() {
    return this.httpClient.get(`https://ds.p1fs.com/legal/text/?title=Global+Privacy+Policy`, { responseType: 'text' });
  }

}
