import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AddressLookupService } from '@app/service/address-lookup.service';

@Component({
  selector: 'additional-locations',
  templateUrl: './additional-locations.component.html',
  styleUrls: ['./additional-locations.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AdditionalLocationsComponent
  implements OnInit, ControlValueAccessor, Validators {
  model = [];
  @Input() form: any = {};
  @Input() pattern: string = null;
  states$: any = [];
  isRequired: boolean = false;
  @ViewChild('addAdditionalLocation') addAdditionalLocation: ElementRef;
  constructor(
    @Self() public controlDir: NgControl,
    private appService: AddressLookupService
  ) {
    this.controlDir.valueAccessor = this;
  }
  ngOnInit() {
    // setTimeout(() => {
    //   this.addAdditionalLocation.nativeElement.click();
    // }, 100);
    return this.appService
      .getStates()
      .subscribe((data) => (this.states$ = data));
  }
  getAddress(zipCode, groupFormName, cityControlName, stateControlName) {
    this.appService.getAddressInfo(zipCode).subscribe((data) => {
      if (data) {
        this.form.controls[groupFormName].controls[cityControlName].setValue(
          data.City
        );
        this.form.controls[groupFormName].controls[stateControlName].setValue(
          data.State
        );
        this.form.controls[groupFormName].controls[
          cityControlName
        ].markAsTouched();
        this.form.controls[groupFormName].controls[
          stateControlName
        ].markAsTouched();
      }
    });
  }
  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }
  onChange(event) {}
  onTouched() {}
  writeValue(obj: any): void {
    this.model = obj || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    return validators;
  }
  addItem() {
    const lastKey = this?.model[this.model.length - 1];
    const lastNumber = +lastKey?.replace(/\D/g, '');
    if (this.model.length == 0) return this.model.push('additionalLocation1');
    if (this.form.controls['additionalLocation' + lastNumber]?.valid) {
      this.model.push('additionalLocation' + (lastNumber + 1));
    } else {
      this.form.controls['additionalLocation' + lastNumber]?.markAllAsTouched();
    }
  }
  removeItem(controlName) {
    const matchIndex = this.model.indexOf(controlName);
    if (matchIndex > -1) this.model.splice(matchIndex, 1);
  }
}
