<h1 class="display-4">Lenders Currently Using</h1>
<div ngModelGroup="lenders" class="mb-4">
  <div *ngFor="let name of model; let index = index">
    <hr class="w-100 mb-4" *ngIf="index >= 1" />
    <h4 class="lead">Lender {{ index + 1 }}</h4>
    <div [ngModelGroup]="name">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lenderName, form)"
              #lenderName="ngModel"
              [name]="'lender' + (index + 1) + ' Name'"
              maxlength="100"
              required
            />
            <small
              class="text-danger"
              *ngIf="
                lenderName.invalid && (lenderName.touched || form.submitted)
              "
              >Name must be between 0-100 characters.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Lender's Dealer Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lenderNumber, form)"
              #lenderNumber="ngModel"
              [name]="'lender' + (index + 1) + ' DealerNumber'"
              maxlength="100"
              pattern="\d+"
            />
            <small
              class="text-danger"
              *ngIf="
                lenderNumber.invalid && (lenderNumber.touched || form.submitted)
              "
              >A valid number between 0-100 digits is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Notes</label>
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(lenderNotes, form)"
              #lenderNotes="ngModel"
              [name]="'lender' + (index + 1) + ' Notes'"
              maxlength="400"
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                lenderNotes.invalid && (lenderNotes.touched || form.submitted)
              "
              >Notes must be between 0-400 characters.</small
            >
          </div>
        </div>
      </div>
      <div class="col-12 text-right" *ngIf="model.length > 0">
        <button
          class="btn btn-danger btn-sm"
          type="button"
          (click)="removeItem(name)"
        >
          Remove Lender
        </button>
      </div>
    </div>
  </div>
</div>
<div class="text-center mb-4" *ngIf="model.length != 6">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    #addLender
    (click)="addItem()"
  >
    Add Lenders
  </button>
</div>
