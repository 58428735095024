<form action="">
  <div class="form-group">
    <label>{{ label }}</label>
    <input
      [(ngModel)]="name"
      [class.is-invalid]="input.invalid"
      class="form-control"
      [type]="type"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [ngStyle]="{ width: width }"
      [name]="name"
      (input)="onChange($event.target.value)"
      (blur)="onTouched()"
      #input
      required
    />
    <div class="text-danger">{{ input.className.invalid }}</div>
  </div>
</form>
