import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMonitorService {

  constructor(private httpClient: HttpClient) { }

  log(event: string, url: string, stateData: any = null) {
    let referrer = document?.referrer;

    const params = {Source: 'DealerSetup', Event:event, Url: url, Referrer: referrer || null};
    for (var propName in params) {
      if (params[propName] === null || params[propName] === undefined) {
        delete params[propName];
      }
    }

    stateData = stateData ? JSON.parse(JSON.stringify(stateData)) : {}; //deep clone

    this.sendToApi(stateData, params);
  }

  sendToApi(data: any = null, params: any = null) {
    return this.httpClient.post(`https://ds.p1fs.com/unifiedmonitor`, data, {params: params})
      .subscribe();
  }
}
