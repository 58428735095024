<h1 class="display-4">Manufacturer Information</h1>
<div *ngFor="let name of model; let index = index">
  <hr class="w-100 mb-4" *ngIf="index >= 1" />
  <div [ngModelGroup]="name" class="mb-4">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Name</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(manufacturerName, form)"
            #manufacturerName="ngModel"
            name="name"
            maxlength="100"
            required
          />
          <small
            class="text-danger"
            *ngIf="
              manufacturerName.invalid &&
              (manufacturerName.touched || form.submitted)
            "
            >Name is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Collateral Type</label>
          <div ngbDropdown class="d-inline-block w-100">
            <button type="button" class="btn btn-outline-primary w-100 collateralDropdown px-4 text-truncate" style="min-height: 50px;border-width: 2px;color: black;" 
              ngbDropdownToggle [ngClass]="checkDropdown(name)">
              <label *ngFor="let selectedCollateral of collateralTypesObj[name]" class="d-inline-flex text-truncate" style="margin: 0;max-width: 80%;">
                <span *ngIf="collateralTypesObj[name].length > 1 && collateralTypesObj[name].indexOf(selectedCollateral) != 0">,&nbsp;</span>{{selectedCollateral | pascaltotitlecase}}
              </label>
            </button> 
            <div ngbDropdownMenu ngModel name="collateralTypes" ngDefaultControl>
              <div *ngFor="let colType of p1ManufacturerTypes" class="mx-2">
                  <input type="checkbox" class="mr-2" (change)="toggleCollateral(name, colType, $event.target.checked)">
                    {{colType | pascaltotitlecase}}
              </div>
            </div>
          </div>
          <small
            class="text-danger"
            *ngIf="
              checkDropdown(name) == 'invalid'
            "
            >Collateral Type(s) required.</small>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Year started carrying collateral</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(manufacturerCollateral, form)"
            #manufacturerCollateral="ngModel"
            name="yearStarted"
            maxlength="4"
            mask="0*"
            pattern="^19\d{2}|2\d{3}$"
          />
          <small
            class="text-danger"
            *ngIf="
              manufacturerCollateral.invalid &&
              (manufacturerCollateral.touched || form.submitted)
            "
            >A valid year is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Estimated Annual Volume</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(manufacturerVolume, form)"
            #manufacturerVolume="ngModel"
            name="estimatedAnnualVolume"
            maxlength="40"
            mask="separator.2"
            thousandSeparator=","
            required
          />
          <small
            class="text-danger"
            *ngIf="
              manufacturerVolume.invalid &&
              (manufacturerVolume.touched || form.submitted)
            "
            >Number is required.</small
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 text-right" *ngIf="model.length > 1">
    <button
      class="btn btn-danger btn-sm"
      type="button"
      (click)="removeItem(name)"
    >
      Remove Manufacturer
    </button>
  </div>
</div>
<div class="text-center mb-4" *ngIf="model.length < 31">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    #addManufacturer
    (click)="addItem()"
  >
    Add Manufacturer
  </button>
</div>
