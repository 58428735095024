<div class="card">
  <div class="card-body">
    <div class="custom-control custom-switch text-right">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customSwitches"
        (click)="showValidation($event.target)"
      />
      <label class="custom-control-label" for="customSwitches"
        >Highlight required fields</label
      >
    </div>
    <h1 class="display-4">Your Business</h1>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit(form)">
      <!-- start of business info your business -->
      <div class="row" ngModelGroup="businessInfo">
        <div class="col-12 col-md-6 col-lg-3">
          <input
            type="hidden"
            name="dae"
            [ngModel]="selectedDae?.value?.name"
          />
          <input
            type="hidden"
            [ngModel]="selectedDae?.value?.email"
            name="daeEmail"
          />
          <div class="form-group">
            <label>Dealer Account Executive</label>
            <select
              class="custom-select"
              ngModel
              name="selectedDae"
              required
              [ngClass]="setClasses(selectedDae, form)"
              #selectedDae="ngModel"
            >
              <option></option>
              <option *ngFor="let value of eqfDae" [ngValue]="value">
                {{ value.name }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  selectedDae.invalid && (selectedDae.touched || form.submitted)
                "
                >Dealer account executive is required.</small
              >
            </div>
          </div>
        </div>
        <!-- error modal start -->
        <ng-template #content let-modal>
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="fas fa-exclamation-circle fa-lg text-danger mr-2"></i
              >Wait a sec...
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              California businesses may require a few extra steps. Please call
              800-419-0952 to speak with
              {{
                selectedDae?.value?.name
                  ? selectedDae.value.name
                  : "one of our Dealer Account Executives "
              }}
              and let's continue this on the phone.
            </p>
          </div>
        </ng-template>
        <!-- end error modal -->
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Lead Source</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(leadSource, form)"
              #leadSource="ngModel"
              name="leadSource"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                leadSource.invalid && (leadSource.touched || form.submitted)
              "
              >Lead source is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Full Legal Business Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessName, form)"
              #businessName="ngModel"
              name="fullLegalBusinessName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessName.invalid && (businessName.touched || form.submitted)
              "
              >Business name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>DBA Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(dbaName, form)"
              #dbaName="ngModel"
              name="dbaName"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="dbaName.invalid && (dbaName.touched || form.submitted)"
              >DBA name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessPhone, form)"
              #businessPhone="ngModel"
              name="phone"
              required
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                businessPhone.invalid &&
                (businessPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Fax</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessFax, form)"
              #businessFax="ngModel"
              name="fax"
              required
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                businessFax.invalid && (businessFax.touched || form.submitted)
              "
              >A valid 10 digit fax number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessEmail, form)"
              #businessEmail="ngModel"
              name="email"
              maxlength="40"
              required
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
            />
            <small
              class="text-danger"
              *ngIf="
                businessEmail.invalid &&
                (businessEmail.touched || form.submitted)
              "
              >Email address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of business info your business -->
      <!-- start of physical address -->
      <div class="row mb-3" ngModelGroup="physicalAddress">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessZip, form)"
              #businessZip="ngModel"
              name="zip"
              required
              mask="00000"
              (change)="
                getAddress(
                  $event.target.value,
                  'physicalAddress',
                  'city',
                  'state'
                )
              "
              maxlength="5"
              minlength="5"
            />
            <small
              class="text-danger"
              *ngIf="
                businessZip.invalid && (businessZip.touched || form.submitted)
              "
              >A valid zip is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessCity, form)"
              #businessCity="ngModel"
              name="city"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessCity.invalid && (businessCity.touched || form.submitted)
              "
              >City is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label class="labelDropdown">State</label>
            <select
              class="custom-select"
              required
              ngModel
              name="state"
              [ngClass]="setClasses(businessState, form)"
              #businessState="ngModel"
              (change)="checkState(businessState, content)"
            >
              <option></option>
              <option
                *ngFor="let value of states$"
                [value]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  businessState.invalid &&
                  (businessState.touched || form.submitted)
                "
                >State is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessAddress, form)"
              #businessAddress="ngModel"
              name="street"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessAddress.invalid &&
                (businessAddress.touched || form.submitted)
              "
              >Street address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of physical address -->
      <!-- start of management -->
      <div class="row" ngModelGroup="management">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Number of Locations</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessLocations, form)"
              #businessLocations="ngModel"
              name="numberOfLocations"
              pattern="[0-9]+"
              mask="00"
            />
            <small
              class="text-danger"
              *ngIf="
                businessLocations.invalid &&
                (businessLocations.touched || form.submitted)
              "
              >A valid number between 0-99 is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Years of Management</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(managementYears, form)"
              #managementYears="ngModel"
              name="yearsUnderCurrentManagement"
              required
              maxlength="2"
              pattern="[0-9]+"
              mask="00"
            />
            <small
              class="text-danger"
              *ngIf="
                managementYears.invalid &&
                (managementYears.touched || form.submitted)
              "
              >A valid number between 0-99 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Organization Type</label>
            <select
              class="custom-select"
              required
              ngModel
              name="organizationType"
              [ngClass]="setClasses(organizationType, form)"
              #organizationType="ngModel"
            >
              <option></option>
              <option *ngFor="let value of orgType" [ngValue]="value">
                {{ value }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  organizationType.invalid &&
                  (organizationType.touched || form.submitted)
                "
                >Organization type is required.</small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end of management -->
      <!-- start of financial info -->
      <div class="row mb-3">
        <div class="col-12 col-md-6 col-lg-6" ngModelGroup="financialInfo">
          <div class="form-group">
            <label>Federal Tax ID (aka FEIN)</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(federalTaxId, form)"
              #federalTaxId="ngModel"
              name="federalTaxId"
              minlength="9"
              mask="00-0000000"
            />
            <small
              class="text-danger"
              *ngIf="
                federalTaxId.invalid && (federalTaxId.touched || form.submitted)
              "
              >A valid 9 digit FEIN is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6" ngModelGroup="businessInfo">
          <div class="form-group">
            <label>Website</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(website, form)"
              #website="ngModel"
              name="website"
            />
            <small
              class="text-danger"
              *ngIf="website.invalid && (website.touched || form.submitted)"
              >A valid website is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of financial info -->
      <!-- start of hours of operation -->
      <div class="col-12 mb-3 mt-3 lead text-center">Hours of Operation</div>
      <div class="row" ngModelGroup="hoursOfOperation">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Monday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnMonday, form)"
              #hoursOnMonday="ngModel"
              name="mon"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnMonday.invalid &&
                (hoursOnMonday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Tuesday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnTuesday, form)"
              #hoursOnTuesday="ngModel"
              name="tue"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnTuesday.invalid &&
                (hoursOnTuesday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Wednesday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnWednesday, form)"
              #hoursOnWednesday="ngModel"
              name="wed"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnWednesday.invalid &&
                (hoursOnWednesday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Thursday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnThursday, form)"
              #hoursOnThursday="ngModel"
              name="thu"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnThursday.invalid &&
                (hoursOnThursday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Friday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnFriday, form)"
              #hoursOnFriday="ngModel"
              name="fri"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnFriday.invalid &&
                (hoursOnFriday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <label>Saturday</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(hoursOnSaturday, form)"
                  #hoursOnSaturday="ngModel"
                  name="sat"
                  maxlength="40"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    hoursOnSaturday.invalid &&
                    (hoursOnSaturday.touched || form.submitted)
                  "
                  >Atleast 0-40 characters is required.
                </small>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <label>Sunday</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(hoursOnSunday, form)"
                  #hoursOnSunday="ngModel"
                  name="sun"
                  maxlength="40"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    hoursOnSunday.invalid &&
                    (hoursOnSunday.touched || form.submitted)
                  "
                  >Atleast 0-40 characters is required.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of hours of operation -->
      <!-- start of products do you sell management -->
      <div class="lead text-center mb-3">
        What types of products do you sell
      </div>
      <div class="row" ngModelGroup="management">
        <div
          class="col-6 col-lg-4 col-md-4 mb-3"
          *ngFor="let text of eqfProducts"
        >
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              [name]="'productType' + text"
              #p1Products="ngModel"
            />
            {{ text }}
            <span
              *ngIf="
                p1Products.invalid && (p1Products.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </label>
        </div>
        <div class="col-6 col-lg-4 col-md-4 mb-3">
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              name="productTypeOtherChecked"
              #productTypeOtherChecked="ngModel"
            />
            Other
            <span
              *ngIf="
                productTypeOtherChecked.invalid &&
                (productTypeOtherChecked.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </label>
        </div>
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-6 mb-3"
              *ngIf="productTypeOtherChecked.value"
            >
              <label>Other</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(productTypeOther, form)"
                #productTypeOther="ngModel"
                name="productTypeOther"
                maxlength="40"
                required
              />
              <small
                class="text-danger"
                *ngIf="
                  productTypeOther.invalid &&
                  (productTypeOther.touched || form.submitted)
                "
                >Other is required.</small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end of products do you sell management -->
      <!-- Principals Start principal -->
      <h1 class="display-4">Principals</h1>
      <div *ngFor="let value of [].constructor(3); let index = index">
        <h4 class="lead">Principal {{ index + 1 }}</h4>
        <div class="row mb-4" [ngModelGroup]="'principal' + (index + 1)">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>First Name</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalFirstName, form)"
                #principalFirstName="ngModel"
                name="firstName"
                maxlength="40"
                [required]="index === 0"
                (change)="concatToFullName()"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalFirstName.invalid &&
                  (principalFirstName.touched || form.submitted)
                "
                >First name is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Middle Name</label>
              <input
                class="form-control"
                type="text"
                [ngClass]="setClasses(principalMiddleName, form)"
                ngModel
                #principalMiddleName="ngModel"
                name="middleName"
                maxlength="40"
                (change)="concatToFullName()"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Last Name</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalLastName, form)"
                #principalLastName="ngModel"
                name="lastName"
                [required]="index === 0"
                maxlength="40"
                (change)="concatToFullName()"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalLastName.invalid &&
                  (principalLastName.touched || form.submitted)
                "
                >Last name is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Percent of Ownership</label>
              <span
                (click)="toolTipModal(toolTipContent)"
                *ngIf="!percentCheck && principalPercentOwnership.touched"
              >
                <i
                  class="fas fa-exclamation-triangle text-warning fa-lg ml-2"
                  data-toggle="tooltip"
                  title="The percentages you entered do not add up to 100%"
                ></i>
              </span>
              <input
                class="form-control"
                [ngClass]="setClasses(principalPercentOwnership, form)"
                type="text"
                ngModel
                #principalPercentOwnership="ngModel"
                name="ownershipPercent"
                mask="percent.2"
                suffix="%"
                pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
                [dropSpecialCharacters]="true"
                (change)="trackPercent()"
              />
              <small
                *ngIf="
                  principalPercentOwnership.invalid &&
                  (principalPercentOwnership.touched || form.submitted)
                "
                class="text-danger"
                >Number must be between 0-100.</small
              >
            </div>
          </div>
          <!-- error modal start -->
          <ng-template #toolTipContent let-modal>
            <div class="modal-header">
              <h4 class="modal-title">
                <i
                  class="fas fa-exclamation-triangle text-warning fa-lg mr-2"
                ></i
                >Percent of Ownership
              </h4>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              The ownership percentages you entered do not add up to 100%.
            </div>
          </ng-template>
          <!-- end error modal -->
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label>Title</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalTitle, form)"
                #principalTitle="ngModel"
                name="title"
                [required]="index === 0"
                maxlength="40"
                (change)="concatToFullName()"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalTitle.invalid &&
                  (principalTitle.touched || form.submitted)
                "
                >Title is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <label>Phone Number</label>
            <div class="input-group">
              <input
                style="width: 60%!important;"
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(phone, form)"
                #phone="ngModel"
                name="phone"
                minlength="10"
                mask="(000) 000-0000"
              />
              <select
                style="width: 40%!important;"
                [ngModel]=""
                #phoneType="ngModel"
                name="phoneType"
                [ngClass]="setClasses(phoneType, form)"
                [required]="phone.value?.length === 10 && phone.valid && phone.touched"
                (ngModelChange)="setSmsOptOut('principal' + (index + 1), $event);"
              >
                <option>Cell - SMS Ok</option>
                <option>Cell - No SMS</option>
                <option>Landline</option>
              </select>
            </div>
            <small
              class="text-danger"
              *ngIf="phone.invalid && (phone.touched || form.submitted)"
              >A valid 10 digit phone number is required.</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group">
              <label>Date of Birth</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalDob, form)"
                #principalDob="ngModel"
                name="dateOfBirth"
                minlength="10"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                pattern="(0[1-9]\/|1[0-2]\/)(0[1-9]\/|[1-2][0-9]\/|3[0-1]\/)(19\d{2}|2\d{3})"
                placeholder="mmddyyyy"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalDob.invalid &&
                  (principalDob.touched || form.submitted)
                "
                >A valid DOB in the format of mm/dd/yyyy is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label>Zip</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalZip, form)"
                #principalZip="ngModel"
                name="zip"
                mask="00000"
                (change)="
                  getAddress(
                    $event.target.value,
                    'principal' + (index + 1),
                    principalCity.name,
                    principalState.name
                  )
                "
                maxlength="5"
                minlength="5"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalZip.invalid &&
                  (principalZip.touched || form.submitted)
                "
                >A valid zip is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>City</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalCity, form)"
                #principalCity="ngModel"
                name="city"
                maxlength="40"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalCity.invalid &&
                  (principalCity.touched || form.submitted)
                "
                >City is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label class="labelDropdown">State</label>
              <select
                class="custom-select"
                ngModel
                name="state"
                [ngClass]="setClasses(principalState, form)"
                #principalState="ngModel"
              >
                <option></option>
                <option
                  *ngFor="let value of states$"
                  [value]="value.StateAbbreviation"
                >
                  {{ value.StateAbbreviation }}
                </option>
              </select>
              <div>
                <small
                  class="text-danger"
                  *ngIf="
                    principalState.invalid &&
                    (principalState.touched || form.submitted)
                  "
                  >State is required.</small
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5">
            <div class="form-group">
              <label>Street Address</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalAddress, form)"
                #principalAddress="ngModel"
                name="homeAddress"
                maxlength="40"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalAddress.invalid &&
                  (principalAddress.touched || form.submitted)
                "
                >Street address is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-5">
            <div class="form-group">
              <label>Email Address</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(principalEmail, form)"
                #principalEmail="ngModel"
                name="EmailAddress"
                maxlength="40"
                pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              />
              <small
                class="text-danger"
                *ngIf="
                  principalEmail.invalid &&
                  (principalEmail.touched || form.submitted)
                "
                >A valid email address is required.</small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end of Principals Start principal -->
      <!-- Start of Sale Info -->
      <h1 class="display-4">Sale Information</h1>
      <div class="row justify-content-center mb-4" ngModelGroup="saleInfo">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Annual Unit Sales</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(annualSales, form)"
              #annualSales="ngModel"
              name="annualUnitSales"
              maxlength="20"
              mask="separator.2"
              thousandSeparator=","
            />
            <small
              class="text-danger"
              *ngIf="
                annualSales.invalid && (annualSales.touched || form.submitted)
              "
              >Annual unit sales is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label>Average Unit Size</label>
            <div class="placeholder" data-placeholder="$">
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(unitSize, form)"
                #unitSize="ngModel"
                name="averageUnitSize"
                maxlength="20"
                mask="separator.2"
                thousandSeparator=","
              />
            </div>
            <small
              class="text-danger"
              *ngIf="unitSize.invalid && (unitSize.touched || form.submitted)"
              >Average unit size is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Financed</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(financed, form)"
              #financed="ngModel"
              name="percentFinanced"
              mask="percent.2"
              suffix="%"
              pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
              [dropSpecialCharacters]="true"
            />
            <small
              class="text-danger"
              *ngIf="financed.invalid && (financed.touched || form.submitted)"
              >A valid number between 0-100 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Municipal</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(municipal, form)"
              #municipal="ngModel"
              name="percentMunicipal"
              mask="percent.2"
              suffix="%"
              pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
              [dropSpecialCharacters]="true"
            />
            <small
              class="text-danger"
              *ngIf="municipal.invalid && (municipal.touched || form.submitted)"
              >A valid number between 0-100 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>What Percent is Commercial</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(commercial, form)"
              #commercial="ngModel"
              name="percentCommercial"
              mask="percent.2"
              suffix="%"
              pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
              [dropSpecialCharacters]="true"
            />
            <small
              class="text-danger"
              *ngIf="
                commercial.invalid && (commercial.touched || form.submitted)
              "
              >A valid number between 0-100 is required.</small
            >
          </div>
        </div>
      </div>
      <!-- End of Sale Info -->
      <!-- start of bank -->
      <h1 class="display-4">Bank of Deposit</h1>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-lg-4" ngModelGroup="bankOfDeposit">
          <div class="form-group">
            <label>Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankName, form)"
              #bankName="ngModel"
              name="name"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="bankName.invalid && (bankName.touched || form.submitted)"
              >Bank name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4" ngModelGroup="bankOfDeposit">
          <div class="form-group">
            <label>Account Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountNumber, form)"
              #accountNumber="ngModel"
              name="account"
              maxlength="20"
              mask="0*"
            />
            <small
              class="text-danger"
              *ngIf="
                accountNumber.invalid &&
                (accountNumber.touched || form.submitted)
              "
              >Account number is required.</small
            >
          </div>
        </div>
        <div
          class="col-12 col-md-6 col-lg-4"
          ngModelGroup="accountInformationForDeposits"
        >
          <div class="form-group">
            <label>Routing Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountRouting, form)"
              #accountRouting="ngModel"
              name="bankRoutingNumber"
              maxlength="9"
              mask="0*"
            />
            <small
              class="text-danger"
              *ngIf="
                accountRouting.invalid &&
                (accountRouting.touched || form.submitted)
              "
              >A valid 9 digit routing number is required</small
            >
          </div>
        </div>
      </div>
      <div class="row" ngModelGroup="accountInformationForDeposits">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankZip, form)"
              mask="00000"
              (change)="
                getAddress(
                  $event.target.value,
                  'accountInformationForDeposits',
                  'bankAddressCity',
                  'bankAddressState'
                )
              "
              #bankZip="ngModel"
              name="bankAddressZip"
              maxlength="5"
              pattern="[0-9]+"
            />
            <small
              class="text-danger"
              *ngIf="bankZip.invalid && (bankZip.touched || form.submitted)"
              >A valid zip is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankCity, form)"
              #bankCity="ngModel"
              name="bankAddressCity"
            />
            <small
              class="text-danger"
              *ngIf="bankCity.invalid && (bankCity.touched || form.submitted)"
              >City is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label class="labelDropdown">State</label>
            <select
              class="custom-select"
              ngModel
              name="bankAddressState"
              [ngClass]="setClasses(bankState, form)"
              #bankState="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of states$"
                [ngValue]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  bankState.invalid && (bankState.touched || form.submitted)
                "
                >State is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankAddress, form)"
              #bankAddress="ngModel"
              name="bankAddressStreet"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                bankAddress.invalid && (bankAddress.touched || form.submitted)
              "
              >Bank address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of bank -->
      <!-- start of Contact information contactInfo -->
      <h1 class="display-4 text-center">Contact Information</h1>
      <div
        *ngFor="let value of contactInfo; let index = index"
        ngModelGroup="contactInfo"
      >
        <div class="row mb-3">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>{{ value }}</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(contactName, form)"
                #contactName="ngModel"
                [name]="value + ' Name'"
                maxlength="100"
              />
              <small
                class="text-danger"
                *ngIf="
                  contactName.invalid && (contactName.touched || form.submitted)
                "
                >A valid name is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Title</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(contactTitle, form)"
                #contactTitle="ngModel"
                [name]="value + ' Title'"
                maxlength="100"
              />
              <small
                class="text-danger"
                *ngIf="
                  contactTitle.invalid &&
                  (contactTitle.touched || form.submitted)
                "
                >A valid title is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <label>Phone Number</label>
            <div class="input-group">
              <input
                style="width: 60%!important;"
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(phone, form)"
                #phone="ngModel"
                [name]="value + ' Phone'"
                minlength="10"
                mask="(000) 000-0000"
              />
              <select
                style="width: 40%!important;"
                [ngModel]=""
                #phoneType="ngModel"
                [name]="value + ' PhoneType'"
                [ngClass]="setClasses(phoneType, form)"
                [required]="phoneType.value?.length === 10 && phoneType.valid && phoneType.touched"
                (ngModelChange)="setSmsOptOut('contactInfo', $event, value);"
              >
                <option>Cell - SMS Ok</option>
                <option>Cell - No SMS</option>
                <option>Landline</option>
              </select>
            </div>
            <small
              class="text-danger"
              *ngIf="phone.invalid && (phone.touched || form.submitted)"
              >A valid 10 digit phone number is required.</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Email</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(contactEmail, form)"
                #contactEmail="ngModel"
                [name]="value + ' email'"
                maxlength="100"
                pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              />
              <small
                class="text-danger"
                *ngIf="
                  contactEmail.invalid &&
                  (contactEmail.touched || form.submitted)
                "
                >A valid email is required.</small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end of Contact information contactInfo -->
      <h4 class="text-center display-4">Agreement</h4>
      <div class="row mb-3" ngModelGroup="signature">
        <p class="pl-3 pr-3">
          In all dealings with Priority One Financial Services, Inc. (P1), an
          independent contractor, Dealer warrants that employees or
          representatives of Dealer will not misrepresent or falsify any
          information pertaining to the transaction, all transactions are valid
          and enforceable according to their terms, all collateral has been
          accurately described by Dealer, the collateral purchased shall be
          solely for business use, all documents provided by Dealer in
          connection with the transaction have been executed by the appropriate
          parties who are duly authorized to execute same, equipment has
          suffered no loss that has not been satisfactorily repaired and
          disclosed to P1, title to the equipment is free and clear of all liens
          and encumbrances. No part of the down payment has been advanced to the
          customer by the dealership. In addition, any down payment within this
          purchase has been accurately described by the dealer and was collected
          from the customer. There has been no fraud on the part of the
          Dealer/Vendor and to the best of the Dealer/Vendor’s knowledge, no
          fraud on the part of any other party to the transaction. All Titles &
          MSOs will need to be listed in Dealer’s name. If not, Dealer will need
          to provide proof of ownership. If Dealer is not handling the
          registration, the Title of the unit will need to be sent to the Lien
          Holder, as instructed by P1.
        </p>
        <p class="pl-3 pr-3">
          If any of the above representations are breached or if fraud is
          committed by Dealer that results in a repurchase demand from the
          financial institution involved in the transaction, within 20 days of
          written notice from P1, Dealer will repurchase any finance contract to
          which the breach relates. The repurchase price will include the unpaid
          balance of the transaction, including past due and future payments
          plus costs and expenses including attorney’s fees incurred to enforce
          this agreement. Dealer will hold P1 harmless from any claims, loss or
          damages arising out of any transaction associated with this agreement
          and/or caused by the breach of any of its representations and
          warranties with respect to this agreement; failure to perform its
          obligations with respect to the unit being purchased; failure to
          comply with any applicable laws, regulations or contract
          specifications; any actions of Dealer’s employees with respect to a
          contract or the sale of a unit; or any misrepresentations or
          falsification of information provided by a customer of Dealer. Dealer
          hereby acknowledges that P1 may receive compensation from a financial
          institution for assisting Dealer with a transaction and consents
          thereto. Dealer authorizes P1 to gather credit information, obtain a
          business credit report to investigate the Dealer’s creditworthiness,
          financial strength and credit capacity for initial program approval.
          By signing below, Dealer represents and warrants that it has the
          express authority and authorization from the principals to allow P1 to
          make the required investigation.
        </p>
        <div class="col-12 col-md-6 col-lg-6" *ngIf="signerNameArr.length > 0">
          <div class="form-group">
            <label class="labelDropdown">Principal</label>
            <select
              class="custom-select"
              name="selectedPrincipal"
              [(ngModel)]="selectedPrincipalModel"
              #selectedPrincipal="ngModel"
              [ngClass]="setClasses(selectedPrincipal, form)"
              required
            >
              <option *ngFor="let value of signerNameArr" [ngValue]="value">
                {{ value.name }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  selectedPrincipal.invalid &&
                  (selectedPrincipal.touched || form.submitted)
                "
                >A valid selection is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6" *ngIf="signerNameArr.length > 0">
          <div class="form-group">
            <label>Signature</label>
            <input
              class="form-control"
              type="text"
              name="signatureText"
              ngModel
              (ngModelChange)="signatureTextChanged(signatureText)"
              #signatureText="ngModel"
              maxlength="100"
              required
              [ngStyle]="{
                'border-color':
                  !signatureText.touched && !form.submitted
                    ? '#0b82f1'
                    : !signatureText.value || isInvalid
                    ? '#d9534f'
                    : '#5cb85c'
              }"
            />
            <small
              class="text-danger"
              *ngIf="
                !signatureText.value &&
                (signatureText.touched || form.submitted)
              "
              >Signature is required.</small
            >
            <small
              class="text-danger"
              *ngIf="
                signatureText.value &&
                isInvalid &&
                (signatureText.touched || form.submitted)
              "
              >Signature must match the name exactly.</small
            >
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          type="submit"
          class="btn btn-success btn-lg"
          [style.background-color]="form.invalid ? 'gray' : '#5cb85c'"
          [style.border]="form.invalid ? 'gray' : '#5cb85c'"
        >
          Submit <i class="far fa-spinner fa-pulse" *ngIf="waiting"></i>
        </button>
      </div>
    </form>
  </div>
</div>
