import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '@app/service/config.service';
//import { daes, orgType, products, stateAbbreviations } from '../data/data';
import { AddressLookupService } from '../service/address-lookup.service';

@Component({
  selector: 'app-frcc',
  templateUrl: './frcc.component.html',
  styleUrls: ['./frcc.component.css'],
})
export class FrccComponent implements OnInit {
  names = this.config.get('daes');
  orgType = this.config.get('orgType');
  products = this.config.get('products');
  stateAbbreviations = this.config.get('stateAbbreviations');
  states$: any = [];

  @ViewChild('form') form: any;

  constructor(private appService: AddressLookupService, private config: ConfigService) {}

  // todo: make all functions reuseable

  validateNumbers = (event) => {
    const parsedValue = parseFloat(event.target.value);
    this.form.controls.percentOwnership.setValue(
      isNaN(parsedValue) ? null : parsedValue
    );
  };

  ngOnInit() {
    return this.appService
      .getStates()
      .subscribe((data) => (this.states$ = data));
  }

  getAddress(zipCode, cityControlName, stateControlName) {
    this.appService.getAddressInfo(zipCode).subscribe((data) => {
      if (data) {
        this.form.controls[cityControlName].setValue(data.City);
        this.form.controls[stateControlName].setValue(data.State);
        this.form.controls[cityControlName].markAsTouched();
        this.form.controls[stateControlName].markAsTouched();
      }
    });
  }

  validateFein(event) {
    let feinDigits = event.target.value.replace(/\D/g, '');
    if (feinDigits.length === 9) {
      event.target.value =
        feinDigits.substr(0, 2) + '-' + feinDigits.substr(2, 10);
      this.form.form.controls.fedTaxId.setValue(event.target.value);
    }
  }

  validatePhoneNumber(event) {
    let eventName = event.target.name;
    let phoneNumDigits = event.target.value.replace(/\D/g, '');
    if (phoneNumDigits.length == 10) {
      event.target.value =
        '(' +
        phoneNumDigits.substr(0, 3) +
        ') ' +
        phoneNumDigits.substr(3, 3) +
        '-' +
        phoneNumDigits.substr(6, 4);
      this.form.controls[eventName].setValue(event.target.value);
    }
  }

  validateZip(event) {
    this.form.form.controls[event.target.name].setValue(
      isNaN(event.target.value) ? null : event.target.value
    );
  }

  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }

  scrollToError(): void {
    const firstElementWithError: HTMLElement = document.querySelector(
      'form .ng-invalid'
    );
    window.scroll({
      top: this.getTopOffset(firstElementWithError),
      left: 0,
      behavior: 'smooth',
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  onSubmit(event) {
    if (event.valid) {
      console.log('submitted');
    }
    this.scrollToError();
  }
}
