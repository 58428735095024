<div class="card">
  <div class="card-body">
    <h1 class="display-4">Dealer Agreement</h1>
    <form
      #dealerForm="ngForm"
      autocomplete="off"
      (ngSubmit)="onSubmit(dealerForm)"
    >
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Dealer Account Executive</label>
            <select
              class="custom-select"
              required
              ngModel
              name="dae"
              [ngClass]="setClasses(dae, dealerForm)"
              #dae="ngModel"
            >
              <option></option>
              <option *ngFor="let value of names" [ngValue]="value">
                {{ value.name }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="dae.invalid && (dae.touched || dealerForm.submitted)"
                >Dealer account executive is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Dealership Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(dealershipName, dealerForm)"
              #dealershipName="ngModel"
              name="dealershipName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                dealershipName.invalid &&
                (dealershipName.touched || dealerForm.submitted)
              "
              >Dealership name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Dealership Phone</label>
            <input
              class="form-control"
              type="text"
              [ngClass]="setClasses(dealershipPhone, dealerForm)"
              ngModel
              #dealershipPhone="ngModel"
              name="dealershipPhone"
              required
              minlength="10"
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                dealershipPhone.invalid &&
                (dealershipPhone.touched || dealerForm.submitted)
              "
              >Valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Owner Name</label>
            <input
              class="form-control"
              type="text"
              [ngClass]="setClasses(ownerName, dealerForm)"
              ngModel
              #ownerName="ngModel"
              name="ownerName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                ownerName.invalid && (ownerName.touched || dealerForm.submitted)
              "
              >Owner name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Owner Title</label>
            <input
              class="form-control"
              type="text"
              [ngClass]="setClasses(ownerTitle, dealerForm)"
              ngModel
              #ownerTitle="ngModel"
              name="ownerTitle"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                ownerTitle.invalid &&
                (ownerTitle.touched || dealerForm.submitted)
              "
              >Owner title is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Percent of Ownership</label>
            <input
              class="form-control"
              [ngClass]="setClasses(percentOwnership, dealerForm)"
              type="text"
              ngModel
              #percentOwnership="ngModel"
              name="percentOwnership"
              required
              mask="percent.2"
              suffix="%"
              pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
              [dropSpecialCharacters]="true"
            />
            <small
              *ngIf="
                percentOwnership.invalid &&
                (percentOwnership.touched || dealerForm.submitted)
              "
              class="text-danger"
              >Number must be between 0-100.</small
            >
          </div>
        </div>
      </div>
      <h1 class="display-4 text-center">Responsibility of Priority One</h1>
      <div class="row">
        <div
          class="col-md-6 p-4"
          *ngFor="let text of p1Data; let checkboxIndex = index"
        >
          <label class="form-check-label font-weight-bold">
            <input
              class="form-check-input"
              type="checkbox"
              required
              ngModel
              [name]="'p1ResponsibilitiesCheckbox' + checkboxIndex"
              #p1ResponsibilitiesCheckbox="ngModel"
            />
            I agree</label
          >
          <span
            *ngIf="
              p1ResponsibilitiesCheckbox.invalid &&
              (p1ResponsibilitiesCheckbox.touched || dealerForm.submitted)
            "
            class="text-danger ml-2"
            >Required.</span
          >
          <p>
            {{ text }}
          </p>
        </div>
      </div>
      <h1 class="display-4">Responsibility of Dealer</h1>
      <div class="row">
        <div
          class="col-md-6 p-4"
          *ngFor="let text of dealerData; let checkboxIndex = index"
        >
          <label class="form-check-label font-weight-bold">
            <input
              class="form-check-input"
              type="checkbox"
              required
              ngModel
              [name]="'dealerResponsibilitiesCheckbox' + checkboxIndex"
              #dealerResponsibilitiesCheckbox="ngModel"
            />
            I agree</label
          >
          <span
            *ngIf="
              dealerResponsibilitiesCheckbox.invalid &&
              (dealerResponsibilitiesCheckbox.touched || dealerForm.submitted)
            "
            class="text-danger ml-2"
            >Required.</span
          >
          <p>
            {{ text }}
          </p>
        </div>
        <div class="col-md-12 mb-3 p-4">
          <label class="form-check-label font-weight-bold">
            <input
              class="form-check-input"
              type="checkbox"
              required
              ngModel
              name="agreementCheckbox"
              #agreementCheckbox="ngModel"
            />
            I agree</label
          >
          <span
            *ngIf="
              agreementCheckbox.invalid &&
              (agreementCheckbox.touched || dealerForm.submitted)
            "
            class="text-danger ml-2"
            >Required.</span
          >
          <p>{{ dealerLi }}</p>

          <ul>
            <li class="col-12 mb-2" *ngFor="let item of dealerListData">
              {{ item }}
            </li>
          </ul>
          <p *ngFor="let item of disclosure">{{ item }}</p>
        </div>
        <button
          type="submit"
          class="btn btn-success btn-lg"
          [style.background-color]="dealerForm.invalid ? 'gray' : '#5cb85c'"
          [style.border]="dealerForm.invalid ? 'gray' : '#5cb85c'"
          [disabled]="waiting"
        >
          Submit <i class="far fa-spinner fa-pulse" *ngIf="waiting"></i>
        </button>
      </div>
    </form>
  </div>
</div>
