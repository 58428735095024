import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AddressLookupService } from './service/address-lookup.service';

import { RemoveCharPipe } from './pipes/remove-char.pipe';

import { AppComponent } from './app.component';
import { DealerSignupComponent } from './dealer-signup/dealer-signup.component';
import { HeaderComponent } from './components/header/header.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputFormComponent } from './components/input-form/input-form.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DealerSignupConfirmationComponent } from './dealer-signup-confirmation/dealer-signup-confirmation.component';
import { ErrorComponent } from './error/error.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { FrccComponent } from './frcc/frcc.component';
import { EqfComponent } from './eqf/eqf.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { P1Component } from './p1/p1.component';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { PrincipalsComponent } from './components/principals/principals.component';
import { LendersComponent } from './components/lenders/lenders.component';
import { AdditionalFeesComponent } from './components/additional-fees/additional-fees.component';
import { ManufacturerComponent } from './components/manufacturer/manufacturer.component';
import { AdditionalLocationsComponent } from './components/additional-locations/additional-locations.component';
import { ConfigService } from './service/config.service';
import { CollateralTypesComponent } from './components/collateral-types/collateral-types.component';
import { CollateralTypesPageComponent } from './collateral-types-page/collateral-types-page.component';
import { VersionHistoryComponent } from './version-history/version-history.component';
import { PascalToTitleCase } from './pipes/pascal-to-title-case.pipe';
import { P1ToggleComponent } from './shared/components/p1-toggle/p1-toggle.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function AppInit(appInitService: ConfigService) {
  return (): Promise<any> => { 
    return appInitService.init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DealerSignupComponent,
    HeaderComponent,
    DropdownComponent,
    InputFormComponent,
    CheckboxComponent,
    DealerSignupConfirmationComponent,
    ErrorComponent,
    FooterComponent,
    PrivacyPolicyModalComponent,
    FrccComponent,
    EqfComponent,
    NotFoundComponent,
    P1Component,
    RemoveCharPipe,
    PascalToTitleCase,
    ComboboxComponent,
    SearchableDropdownComponent,
    PrincipalsComponent,
    LendersComponent,
    AdditionalFeesComponent,
    ManufacturerComponent,
    AdditionalLocationsComponent,
    CollateralTypesComponent,
    CollateralTypesPageComponent,
    VersionHistoryComponent,
    P1ToggleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    AddressLookupService, 
    ConfigService, 
    { provide: APP_INITIALIZER, useFactory: AppInit, deps: [ConfigService], multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
