import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.css'],
})
export class PrivacyPolicyModalComponent {
  constructor(private modal: NgbModal) {}
  open(content) {
    this.modal.open(content, { centered: true });
  }
}
