import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AddressLookup } from '../models/address-lookup.model';

@Injectable({
  providedIn: 'root',
})
export class AddressLookupService {
  readonly API_URL = 'https://ds.p1fs.com/address/';

  constructor(private http: HttpClient) {}
  getAddressInfo(zip) {
    if (zip) {
      return this.http.get<AddressLookup>(`${this.API_URL}zipcode/${zip}`);
    } else {
      return of(null);
    }
  }

  getStates() {
    return this.http.get(`${this.API_URL}states`);
  }
}
