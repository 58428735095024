<label *ngIf="labelMode == 'string'" class="d-inline-block position-relative transition-all" style="font-size: 0.875rem!important; line-height: 1.25rem; width: max-content; height: 1.75em; border-radius: 9999px!important;" [class]="className" [ngClass]="toggleValue ? trueClass: falseClass">
    <!--checkbox primitve that holds the value-->
    <input type="checkbox" class="d-none" (ngModelChange)="setValue($event)" [ngModel]="toggleValue">
    <!--hacky way of setting the width of the container to the max of the widths for each label-->
    <div class="d-flex flex-column" style="overflow-y: hidden; height: 0;">
        <div class="d-flex flex-row mx-4 h-full--4px"><div style="aspect-ratio: 1 / 1;"></div>{{trueLabel}}</div>
        <div class="d-flex flex-row mx-4 h-full--4px"><div style="aspect-ratio: 1 / 1;"></div>{{falseLabel}}</div>
    </div>
    <!--display of yes/no label inside the toggle-->
    <div class="mx-2 text-white d-flex flex-column justify-content-center" style="height: 100%; margin-top: 1px;" [ngClass]="toggleValue ? 'text-left' : 'text-right'">{{toggleValue ? trueLabel : falseLabel}}</div>
    <!--the indicator ball-->
    <div class="bg-white position-absolute -translate-y-1/2 transition-all h-full--4px" [ngClass]="{'-translate-x-custom':toggleValue}" style="aspect-ratio: 1 / 1; top: 10%!important; border-radius: 9999px!important;" [ngStyle]="{'left': toggleValue ? '74%' : '2px'}"></div>
</label>
<!-- Need to finish tailwind to bootstrap translation -->
<label *ngIf="labelMode == 'iconClass'" class="small d-inline-flex flex-row position-relative transition-all " style="border-radius: 9999px!important;" [ngClass]="toggleValue ? trueClass: falseClass">
    <!--checkbox primitve that holds the value-->
    <input type="checkbox" class="d-none" (ngModelChange)="setValue($event)" [ngModel]="toggleValue">
    <!--display of true/false icons inside the toggle-->
    <div class="my-1 text-white opacity-80" [ngClass]="toggleValue ? 'text-left ml-2 mr-5 ' + trueLabel : 'text-right ml-5 mr-2 ' + falseLabel"></div>
    <!--the indicator ball-->
    <div class="bg-white position-absolute -translate-y-1/2 transition-all h-full--4px" [ngClass]="{'-translate-x-custom':toggleValue}" style="aspect-ratio: 1 / 1; top: 10%!important; border-radius: 9999px!important;" [ngStyle]="{'left': toggleValue ? '62%' : '2px'}"></div>
</label>
<label *ngIf="labelMode == 'checkAndX' || labelMode == 'thumbsUpAndDown' || labelMode == 'moonAndSun' || labelMode == 'smileys'" class="d-inline-flex flex-row position-relative transition-all" style="width: 3.5rem!important; border-radius: 9999px!important;" [ngClass]="toggleValue ? trueClass: falseClass">
    <!--checkbox primitve that holds the value-->
    <input type="checkbox" class="d-none" (ngModelChange)="setValue($event)" [ngModel]="toggleValue">
    <!--display of true/false icons inside the toggle-->
    <div class="my-1 text-white w-100" style="opacity: 0.8;" [ngClass]="toggleValue ? 'text-left ml-2 mr-5 ' + icons[labelMode]['true'] : 'text-right ml-5 mr-2 ' + icons[labelMode]['false']"></div>
    <!--the indicator ball-->
    <div class="bg-white position-absolute -translate-y-1/2 transition-all h-full--4px" [ngClass]="{'-translate-x-custom':toggleValue}" style="aspect-ratio: 1 / 1; top: 10%!important; border-radius: 9999px!important;" [ngStyle]="{'left': toggleValue ? '62%' : '2px'}"></div>
</label>
<!-- Need to finish tailwind to bootstrap translation -->
<label *ngIf="labelMode == 'yesAndNo'" class=" d-inline-flex flex-row position-relative transition-all w-14" style="border-radius: 9999px!important;" [ngClass]="toggleValue ? trueClass: falseClass">
    <!--checkbox primitve that holds the value-->
    <input type="checkbox" class="d-none" (ngModelChange)="setValue($event)" [ngModel]="toggleValue">
    <!--display of true/false icons inside the toggle-->
    <div class="my-1 text-xs translate-y-[1px] font-bold  text-white opacity-90 w-full" [ngClass]="toggleValue ? 'text-left ml-[.62rem] mr-5 ' : 'text-right ml-5 mr-[.68rem] '">{{toggleValue ? 'YES' : 'NO'}}</div>
    <!--the indicator ball-->
    <div class="bg-white position-absolute -translate-y-1/2 transition-all h-full--4px" [ngClass]="{'-translate-x-custom':toggleValue}" style="aspect-ratio: 1 / 1; top: 10%!important; border-radius: 9999px!important;" [ngStyle]="{'left': toggleValue ? '62%' : '2px'}"></div>
</label>

