<h1 class="display-4">Additional Locations</h1>
<div *ngFor="let name of model; let index = index">
  <hr class="w-100 mb-4" *ngIf="index >= 1" />
  <h4 class="lead">Additional Location {{ index + 1 }}</h4>
  <div [ngModelGroup]="name" class="mb-4 row">
    <div class="col-12 col-md-6 col-lg-2">
      <div class="form-group">
        <label>Zip</label>
        <input
          class="form-control"
          type="text"
          ngModel
          [ngClass]="setClasses(additionalLocationZip1, form)"
          #additionalLocationZip1="ngModel"
          name="zip"
          mask="00000"
          (change)="getAddress($event.target.value, name, 'city', 'state')"
          maxlength="5"
          minlength="5"
          required
        />
        <small
          class="text-danger"
          *ngIf="
            additionalLocationZip1.invalid &&
            (additionalLocationZip1.touched || form.submitted)
          "
          >A valid zip is required.</small
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
      <div class="form-group">
        <label>City</label>
        <input
          class="form-control"
          type="text"
          ngModel
          [ngClass]="setClasses(additionalLocationCity1, form)"
          #additionalLocationCity1="ngModel"
          name="city"
          maxlength="40"
          required
        />
        <small
          class="text-danger"
          *ngIf="
            additionalLocationCity1.invalid &&
            (additionalLocationCity1.touched || form.submitted)
          "
          >City is required.</small
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-2">
      <div class="form-group">
        <label>State</label>
        <select
          class="custom-select"
          ngModel
          name="state"
          [ngClass]="setClasses(additionalLocationState1, form)"
          #additionalLocationState1="ngModel"
          required
        >
          <option></option>
          <option
            *ngFor="let value of states$"
            [value]="value.StateAbbreviation"
          >
            {{ value.StateAbbreviation }}
          </option>
        </select>
        <div>
          <small
            class="text-danger"
            *ngIf="
              additionalLocationState1.invalid &&
              (additionalLocationState1.touched || form.submitted)
            "
            >State is required.</small
          >
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-5">
      <div class="form-group">
        <label>Street Address</label>
        <input
          class="form-control"
          type="text"
          ngModel
          [ngClass]="setClasses(additionalLocationAddress1, form)"
          #additionalLocationAddress1="ngModel"
          name="address"
          maxlength="100"
          required
        />
        <small
          class="text-danger"
          *ngIf="
            additionalLocationAddress1.invalid &&
            (additionalLocationAddress1.touched || form.submitted)
          "
          >Street address is required.</small
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-3">
      <div class="form-group">
        <label>Phone</label>
        <input
          class="form-control"
          type="text"
          ngModel
          [ngClass]="setClasses(additionalLocationPhone1, form)"
          #additionalLocationPhone1="ngModel"
          name="phone"
          minlength="10"
          mask="(000) 000-0000"
        />
        <small
          class="text-danger"
          *ngIf="
            additionalLocationPhone1.invalid &&
            (additionalLocationPhone1.touched || form.submitted)
          "
          >A valid 10 digit phone number is required.</small
        >
      </div>
    </div>
  </div>
  <div class="col-12 text-right" *ngIf="model.length > 0">
    <button
      class="btn btn-danger btn-sm"
      type="button"
      (click)="removeItem(name)"
    >
      Remove Additional Location
    </button>
  </div>
</div>
<div class="text-center mb-5" *ngIf="model.length != 3">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    #addAdditionalLocation
    (click)="addItem()"
  >
    Add Another Location
  </button>
</div>
