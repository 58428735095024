<!-- <div class="col-12 mb-3 lead text-center">
  What types of products do you sell?
</div> -->
<div class="col-12" style="white-space: nowrap;">
    <div class="row w-75 ml-sm-auto" ngModelGroup="management">
        <div class="col-12 col-lg-6 col-md-6">
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypeRV"
                        #p1Products="ngModel"
                        (change)="toggleShowRVSubType(form)"
                    />
                        RV
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
                <div [ngClass]="{'d-none': hideRVSubType}" class="ml-3">
                    <div *ngFor="let item of rvProducts">
                        <label class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                ngModel
                                [name]="'subType' + item"
                                #p1Products="ngModel"
                                (change)="setSubtype('RV', item, $event.target.checked)"
                            />
                            {{ item }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypePWC"
                        #p1Products="ngModel"
                        (change)="setType('PWC', $event.target.checked)"
                    />
                    PWC
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
            </div>
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypeMarine"
                        #p1Products="ngModel"
                        (change)="setType('Marine', $event.target.checked)"
                    />
                    Marine
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 mb-4">
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypePowersports"
                        #p1Products="ngModel"
                        (change)="toggleShowPowerSportsSubType(form)"
                    />
                    Powersports
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
                <div [ngClass]="{'d-none': hidePowerSportsSubType}" class="ml-3">
                    <div *ngFor="let item of powerSportsProducts">
                        <label class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                ngModel
                                [name]="'subType' + item"
                                #p1Products="ngModel"
                                (change)="setSubtype('PowerSports', item, $event.target.checked)"
                            />
                            {{ item }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypeHorse Trailer"
                        #p1Products="ngModel"
                        (change)="toggleShowHorseTrailerSubType(form)"
                    />
                    Horse Trailer
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
                <div [ngClass]="{'d-none': hideHorseTrailerSubType}" class="ml-3">
                    <div *ngFor="let item of horseTrailerProducts">
                        <label class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                ngModel
                                [name]="'subType' + item"
                                #p1Products="ngModel"
                                (change)="setSubtype('Horse Trailer', item, $event.target.checked)"
                            />
                            {{ item }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        ngModel
                        name="productTypeOther"
                        #p1Products="ngModel"
                        (change)="setType('Other', $event.target.checked)"
                    />
                    Other
                    <span
                        *ngIf="p1Products.invalid && (p1Products.touched || form.submitted)"
                        class="text-danger ml-2"
                    >
                        Required.
                    </span>
                </label>
            </div>
        </div>
    </div>
</div>