import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'lenders',
  templateUrl: './lenders.component.html',
  styleUrls: ['./lenders.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class LendersComponent implements ControlValueAccessor, Validators {
  model = [];
  @Input() form: any = {};
  @Input() pattern: string = null;
  isRequired: boolean = false;
  @ViewChild('addLender') addLender: ElementRef;
  constructor(@Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }
  // ngOnInit(): void {
  //   setTimeout(() => {
  //     this.addLender.nativeElement.click();
  //   }, 100);
  // }
  onChange(event) {}
  onTouched() {}
  writeValue(obj: any): void {
    this.model = obj || [];
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
  validate(c: AbstractControl): { [key: string]: any } {
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }
    return validators;
  }
  setClasses(event, form) {
    let myClassess = {
      valid: event.valid && (event.touched || form.submitted),
      invalid: event.invalid && (event.touched || form.submitted),
    };
    return myClassess;
  }
  addItem() {
    const lastKey = this?.model[this.model.length - 1];
    const lastNumber = +lastKey?.replace(/\D/g, '');
    if (this.model.length == 0) {
      return this.model.push('lender1');
    }
    if (this.form.controls.lenders.controls[`lender${lastNumber}`]?.valid) {
      this.model.push('lender' + (lastNumber + 1));
    } else {
      this.form.controls.lenders.markAllAsTouched();
    }
  }
  removeItem(controlName) {
    const matchIndex = this.model.indexOf(controlName);
    if (matchIndex > -1) {
      this.model.splice(matchIndex, 1);
    }
  }
}
