<h1 class="display-4">Principals</h1>
<div *ngFor="let name of model; let index = index">
  <hr class="w-100 mb-4" *ngIf="index >= 1" />
  <h4 class="lead">Principal {{ index + 1 }}</h4>
  <div [ngModelGroup]="name" class="mb-4">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>First Name</label>
          <input
            class="form-control"
            type="text"
            [ngClass]="setClasses(firstName, form)"
            #firstName="ngModel"
            ngModel
            name="firstName"
            maxlength="40"
            required
          />
          <small
            class="text-danger"
            *ngIf="firstName.invalid && (firstName.touched || form.submitted)"
            >First name is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Middle Name</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(middleName, form)"
            ngModel
            #middleName="ngModel"
            name="middleName"
            maxlength="40"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Last Name</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(lastName, form)"
            #lastName="ngModel"
            name="lastName"
            maxlength="40"
            required
          />
          <small
            class="text-danger"
            *ngIf="lastName.invalid && (lastName.touched || form.submitted)"
            >Last name is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Percent of Ownership</label>
          <span
            (click)="toolTipModal(toolTipContent)"
            *ngIf="!percentCheck && principalPercentOwnership.touched"
          >
            <i
              class="fas fa-exclamation-triangle text-warning fa-lg ml-2"
              data-toggle="tooltip"
              title="The percentages you entered do not add up to 100%"
            ></i>
          </span>
          <input
            class="form-control"
            [ngClass]="setClasses(principalPercentOwnership, form)"
            type="text"
            ngModel
            #principalPercentOwnership="ngModel"
            name="ownershipPercent"
            mask="percent.2"
            suffix="%"
            pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
            [dropSpecialCharacters]="true"
            (change)="trackPercent()"
          />
          <small
            *ngIf="
              principalPercentOwnership.invalid &&
              (principalPercentOwnership.touched || form.submitted)
            "
            class="text-danger"
            >Number must be between 0-100.</small
          >
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Title</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalTitle, form)"
            #principalTitle="ngModel"
            name="title"
            [required]="index === 0"
            maxlength="40"
          />
          <small
            class="text-danger"
            *ngIf="
              principalTitle.invalid &&
              (principalTitle.touched || form.submitted)
            "
            >Title is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <label>Phone Number</label>
        <div class="input-group">
          <input
            class="form-control"
            style="width: 60%!important;"
            type="text"
            ngModel
            [ngClass]="setClasses(phone, form)"
            #phone="ngModel"
            name="phone"
            minlength="10"
            mask="(000) 000-0000"
          />
          <select
            style="width: 40%!important;"
            id="phoneType"
            ngModel
            #phoneType="ngModel"
            name="phoneType"
            [required]="phone.value?.length === 10 && phone.valid && phone.touched"
            [ngClass]="setClasses(phoneType, form)"
            (ngModelChange)="setSmsOptOut('principal' + (index + 1), $event);"
          >
            <option>Cell - SMS Ok</option>
            <option>Cell - No SMS</option>
            <option>Landline</option>
          </select>
        </div>
        <small
          class="text-danger"
          *ngIf="phone.invalid && (phone.touched || form.submitted)"
          >A valid 10 digit phone number is required.</small
        >
        <small
          class="text-danger"
          *ngIf="phoneType.invalid && (phoneType.touched || form.submitted)"
          >You must select a phone type.</small
        >
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>Date of Birth</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalDob, form)"
            #principalDob="ngModel"
            name="dateOfBirth"
            minlength="10"
            mask="00/00/0000"
            [dropSpecialCharacters]="false"
            pattern="(0[1-9]\/|1[0-2]\/)(0[1-9]\/|[1-2][0-9]\/|3[0-1]\/)(19\d{2}|2\d{3})"
            placeholder="mmddyyyy"
          />
          <small
            class="text-danger"
            *ngIf="
              principalDob.invalid && (principalDob.touched || form.submitted)
            "
            >A valid DOB in the format of mm/dd/yyyy is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>SSN</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalSsn, form)"
            name="ssn"
            #principalSsn="ngModel"
            minlength="9"
            mask="000-00-0000"
          />
          <small
            class="text-danger"
            *ngIf="
              principalSsn.invalid && (principalSsn.touched || form.submitted)
            "
            >A valid SSN is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <div class="form-group">
          <label>Zip</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalZip, form)"
            #principalZip="ngModel"
            name="zip"
            mask="00000"
            (change)="
              getAddress(
                $event.target.value,
                'principal' + (index + 1),
                principalCity.name,
                principalState.name
              )
            "
            maxlength="5"
            minlength="5"
          />
          <small
            class="text-danger"
            *ngIf="
              principalZip.invalid && (principalZip.touched || form.submitted)
            "
            >A valid zip is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label>City</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalCity, form)"
            #principalCity="ngModel"
            name="city"
            maxlength="40"
          />
          <small
            class="text-danger"
            *ngIf="
              principalCity.invalid && (principalCity.touched || form.submitted)
            "
            >City is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-2">
        <div class="form-group">
          <label>State</label>
          <select
            class="custom-select"
            ngModel
            name="state"
            [ngClass]="setClasses(principalState, form)"
            #principalState="ngModel"
          >
            <option></option>
            <option
              *ngFor="let value of states$"
              [value]="value.StateAbbreviation"
            >
              {{ value.StateAbbreviation }}
            </option>
          </select>
          <div>
            <small
              class="text-danger"
              *ngIf="
                principalState.invalid &&
                (principalState.touched || form.submitted)
              "
              >State is required.</small
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <div class="form-group">
          <label>Street Address</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalAddress, form)"
            #principalAddress="ngModel"
            name="homeAddress"
            maxlength="40"
          />
          <small
            class="text-danger"
            *ngIf="
              principalAddress.invalid &&
              (principalAddress.touched || form.submitted)
            "
            >Street address is required.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5">
        <div class="form-group">
          <label>Email Address</label>
          <input
            class="form-control"
            type="text"
            ngModel
            [ngClass]="setClasses(principalEmail, form)"
            #principalEmail="ngModel"
            name="EmailAddress"
            maxlength="40"
            pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
          />
          <small
            class="text-danger"
            *ngIf="
              principalEmail.invalid &&
              (principalEmail.touched || form.submitted)
            "
            >A valid email address is required.</small
          >
        </div>
      </div>
    </div>
    <div class="col-12 text-right" *ngIf="model.length != 1">
      <button
        class="btn btn-danger btn-sm"
        type="button"
        (click)="removeItem(name)"
      >
        Remove Principal
      </button>
    </div>
  </div>
</div>
<div class="text-center mb-4" *ngIf="model.length != 5">
  <button
    type="button"
    class="btn btn-primary btn-lg"
    #addPrincipal
    (click)="addItem()"
  >
    Add Principals
  </button>
</div>
<ng-template #toolTipContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fas fa-exclamation-triangle text-warning fa-lg mr-2"></i>Percent
      of Ownership
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    The ownership percentages you entered do not add up to 100%.
  </div>
</ng-template>
