<h1 class="text-center" *ngIf="!displayInfo">No data to show...</h1>
<ngb-accordion
  #acc="ngbAccordion"
  [closeOthers]="true"
  [animation]="true"
  activeIds="ngb-panel-0"
  *ngIf="displayInfo"
>
  <ngb-panel *ngFor="let item of versionData" style="margin: 0px; padding: 0px;">
    <ng-template ngbPanelHeader>
      <div class="header">
        <button ngbPanelToggle class="accordion-button w-100">
            <div class="d-flex">
                <h4>
                    Version {{ item.Version }}
                </h4>
                <p class="ml-5">Live on: {{ item.LiveDate }}</p>
            </div>
        </button>
      </div>
    </ng-template>
    <ng-template ngbPanelContent style="margin: 0px; padding: 0px">
      <div *ngIf="item.Additions">
          <ul> <b>Additions:</b>
              <li *ngFor="let addition of item.Additions" class="ml-3">{{addition}}</li>
          </ul>
      </div>
      <div *ngIf="item.Changes">
        <ul> <b>Changes:</b>
            <li *ngFor="let change of item.Changes" class="ml-3">{{change}}</li>
        </ul>
    </div>
    <div *ngIf="item.Bugs">
        <ul> <b>Bug Fixes:</b>
            <li *ngFor="let bug of item.Bugs" class="ml-3">{{bug}}</li>
        </ul>
    </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>