<div class="card">
  <div class="card-body">
    <div class="custom-control custom-switch text-right">
      <input
        type="checkbox"
        class="custom-control-input"
        id="customSwitches"
        (click)="showValidation($event.target)"
      />
      <label class="custom-control-label" for="customSwitches"
        >Highlight required fields</label
      >
    </div>
    <h1 class="display-4">Your Business</h1>
    <form #form="ngForm" autocomplete="off" (ngSubmit)="onSubmit(form)">
      <!-- Your Business Section Start -->
      <div class="row" ngModelGroup="businessInfo">
        <div class="col-12 col-md-6 col-lg-3">
          <input
            type="hidden"
            name="dae"
            [ngModel]="selectedDae?.value?.name"
          />
          <input
            type="hidden"
            [ngModel]="selectedDae?.value?.email"
            name="daeEmail"
          />
          <div class="form-group">
            <label>Dealer Account Executive</label>
            <select
              class="custom-select"
              ngModel
              name="selectedDae"
              required
              [ngClass]="setClasses(selectedDae, form)"
              #selectedDae="ngModel"
            >
              <option></option>
              <option *ngFor="let value of names" [ngValue]="value">
                {{ value.name }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  selectedDae.invalid && (selectedDae.touched || form.submitted)
                "
                >Dealer account executive is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Lead Source</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(leadSource, form)"
              #leadSource="ngModel"
              name="leadSource"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                leadSource.invalid && (leadSource.touched || form.submitted)
              "
              >Lead source is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Full Legal Business Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessName, form)"
              #businessName="ngModel"
              name="fullLegalBusinessName"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                businessName.invalid && (businessName.touched || form.submitted)
              "
              >Business name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>DBA Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(dbaName, form)"
              #dbaName="ngModel"
              name="dbaName"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="dbaName.invalid && (dbaName.touched || form.submitted)"
              >DBA name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessPhone, form)"
              #businessPhone="ngModel"
              name="phone"
              required
              minlength="10"
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                businessPhone.invalid &&
                (businessPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Fax</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessFax, form)"
              #businessFax="ngModel"
              name="fax"
              required
              minlength="10"
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                businessFax.invalid && (businessFax.touched || form.submitted)
              "
              >A valid 10 digit fax number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Website</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessWebsite, form)"
              #businessWebsite="ngModel"
              name="website"
              maxlength="60"
            />
            <small
              class="text-danger"
              *ngIf="
                businessWebsite.invalid &&
                (businessWebsite.touched || form.submitted)
              "
              >A valid website is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Email Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessEmail, form)"
              #businessEmail="ngModel"
              name="email"
              maxlength="40"
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
            />
            <small
              class="text-danger"
              *ngIf="
                businessEmail.invalid &&
                (businessEmail.touched || form.submitted)
              "
              >A valid email address is required.</small
            >
          </div>
        </div>
      </div>
      <h4 class="lead">Physical Address</h4>
      <!-- start of physical address -->
      <div ngModelGroup="physicalAddress" class="row">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessZip, form)"
              #businessZip="ngModel"
              name="zip"
              required
              mask="00000"
              (change)="
                getAddress(
                  $event.target.value,
                  'physicalAddress',
                  'city',
                  'state'
                )
              "
              maxlength="5"
              minlength="5"
            />
            <small
              class="text-danger"
              *ngIf="
                businessZip.invalid && (businessZip.touched || form.submitted)
              "
              >A valid zip is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(physicalCity, form)"
              name="city"
              #physicalCity="ngModel"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                physicalCity.invalid && (physicalCity.touched || form.submitted)
              "
              >City is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>State</label>
            <select
              class="custom-select"
              required
              ngModel
              name="state"
              [ngClass]="setClasses(physicalState, form)"
              #physicalState="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of states$"
                [value]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  physicalState.invalid &&
                  (physicalState.touched || form.submitted)
                "
                >State is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(physicalAddress, form)"
              #physicalAddress="ngModel"
              name="street"
              required
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                physicalAddress.invalid &&
                (physicalAddress.touched || form.submitted)
              "
              >Street address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of physical address -->
      <!-- start of mailing address -->
      <h4 class="lead">Mailing Address</h4>
      <div class="row" ngModelGroup="mailingAddress">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(mailingZip, form)"
              #mailingZip="ngModel"
              name="zip"
              mask="00000"
              (change)="
                getAddress(
                  $event.target.value,
                  'mailingAddress',
                  'city',
                  'state'
                )
              "
              maxlength="5"
              minlength="5"
            />
            <small
              class="text-danger"
              *ngIf="
                mailingZip.invalid && (mailingZip.touched || form.submitted)
              "
              >A valid zip is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(mailingCity, form)"
              name="city"
              #mailingCity="ngModel"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                mailingCity.invalid && (mailingCity.touched || form.submitted)
              "
              >City is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>State</label>
            <select
              class="custom-select"
              ngModel
              name="state"
              [ngClass]="setClasses(mailingState, form)"
              #mailingState="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of states$"
                [value]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  mailingState.invalid &&
                  (mailingState.touched || form.submitted)
                "
                >State is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(mailingAddress, form)"
              #mailingAddress="ngModel"
              name="street"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                mailingAddress.invalid &&
                (mailingAddress.touched || form.submitted)
              "
              >Street address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of mailing address -->
      <!-- start of billing address -->
      <h4 class="lead">Billing Address</h4>
      <div class="row" ngModelGroup="billingAddress">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Zip</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(billingZip, form)"
              #billingZip="ngModel"
              name="zip"
              mask="00000"
              (change)="
                getAddress(
                  $event.target.value,
                  'billingAddress',
                  'city',
                  'state'
                )
              "
              maxlength="5"
              minlength="5"
            />
            <small
              class="text-danger"
              *ngIf="
                billingZip.invalid && (billingZip.touched || form.submitted)
              "
              >A valid zip is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>City</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(billingCity, form)"
              name="city"
              #billingCity="ngModel"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                billingCity.invalid && (billingCity.touched || form.submitted)
              "
              >City is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>State</label>
            <select
              class="custom-select"
              ngModel
              name="state"
              [ngClass]="setClasses(billingState, form)"
              #billingState="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of states$"
                [value]="value.StateAbbreviation"
              >
                {{ value.StateAbbreviation }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  billingState.invalid &&
                  (billingState.touched || form.submitted)
                "
                >State is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-group">
            <label>Street Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(billingAddress, form)"
              #billingAddress="ngModel"
              name="address"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                billingAddress.invalid &&
                (billingAddress.touched || form.submitted)
              "
              >Street address is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of billing address -->
      <!-- start of management row -->
      <div class="row" ngModelGroup="management">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Years at Present Address</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(yearsAtPresentAddress, form)"
              #yearsAtPresentAddress="ngModel"
              name="yearsAtPresentAddress"
              pattern="[0-9]+"
              mask="00"
            />
            <small
              class="text-danger"
              *ngIf="
                yearsAtPresentAddress.invalid &&
                (yearsAtPresentAddress.touched || form.submitted)
              "
              >A valid number between 0-99 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Year Business Established</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(dateBusinessEstablished, form)"
              #dateBusinessEstablished="ngModel"
              name="dateBusinessEstablished"
              pattern="^19\d{2}|2\d{3}$"
              mask="0000"
              required
            />
            <small
              class="text-danger"
              *ngIf="
                dateBusinessEstablished.invalid &&
                (dateBusinessEstablished.touched || form.submitted)
              "
              >A valid year is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Years Under Current Management</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(yearsUnderCurrentManagement, form)"
              #yearsUnderCurrentManagement="ngModel"
              name="yearsUnderCurrentManagement"
              required
              mask="00"
            />
            <small
              class="text-danger"
              *ngIf="
                yearsUnderCurrentManagement.invalid &&
                (yearsUnderCurrentManagement.touched || form.submitted)
              "
              >A valid number between 0-99 is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Organization Type</label>
            <select
              class="custom-select"
              required
              ngModel
              name="organizationType"
              [ngClass]="setClasses(organizationType, form)"
              #organizationType="ngModel"
            >
              <option></option>
              <option *ngFor="let value of orgType" [ngValue]="value">
                {{ value }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  organizationType.invalid &&
                  (organizationType.touched || form.submitted)
                "
                >Organization type is required.</small
              >
            </div>
          </div>
        </div>
      </div>
      <!-- end of management row -->
      <!-- start of hours of operation -->
      <div class="col-12 mb-3 mt-3 lead text-center">Hours of Operation</div>
      <div class="row" ngModelGroup="hoursOfOperation">
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Monday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnMonday, form)"
              #hoursOnMonday="ngModel"
              name="mon"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnMonday.invalid &&
                (hoursOnMonday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Tuesday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnTuesday, form)"
              #hoursOnTuesday="ngModel"
              name="tue"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnTuesday.invalid &&
                (hoursOnTuesday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Wednesday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnWednesday, form)"
              #hoursOnWednesday="ngModel"
              name="wed"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnWednesday.invalid &&
                (hoursOnWednesday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Thursday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnThursday, form)"
              #hoursOnThursday="ngModel"
              name="thu"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnThursday.invalid &&
                (hoursOnThursday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <div class="form-group">
            <label>Friday</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(hoursOnFriday, form)"
              #hoursOnFriday="ngModel"
              name="fri"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="
                hoursOnFriday.invalid &&
                (hoursOnFriday.touched || form.submitted)
              "
              >Atleast 0-40 characters is required.
            </small>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <label>Saturday</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(hoursOnSaturday, form)"
                  #hoursOnSaturday="ngModel"
                  name="sat"
                  maxlength="40"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    hoursOnSaturday.invalid &&
                    (hoursOnSaturday.touched || form.submitted)
                  "
                  >Atleast 0-40 characters is required.
                </small>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-2">
              <div class="form-group">
                <label>Sunday</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(hoursOnSunday, form)"
                  #hoursOnSunday="ngModel"
                  name="sun"
                  maxlength="40"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    hoursOnSunday.invalid &&
                    (hoursOnSunday.touched || form.submitted)
                  "
                  >Atleast 0-40 characters is required.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of hours of operation -->
      <div class="col-12 mb-3 lead text-center" [ngClass]="{'ng-invalid': subtypesNeeded || invalidCollateralType}">
        What types of products do you sell?
      </div>
      <collateral-types [form]="form"
      (invalidFormChange)="collateralTypeInvalid($event)"
      (needSubtypeChange)="needCollateralSubtype($event)"></collateral-types>
      <div class="text-center mb-3 mt-n2">
        <span *ngIf="invalidCollateralType" class="text-danger">
          Please select at least one collateral type.
        </span>
        <span *ngIf="subtypesNeeded" class="text-danger">
            Please select a subtype in the category above.
        </span>
      </div>
      <!-- start of management row -->
      <div class="row" ngModelGroup="management">
        <div
          class="col-12 col-md-6 col-lg-6 mb-3"
          *ngIf="physicalState.value === 'FL'"
        >
          <label> Does the business have a retail license? </label>
          <br />
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="hasRetailSellersLicense"
                ngModel
                #hasRetailSellersLicense="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="hasRetailSellersLicense"
                ngModel
                #hasRetailSellersLicense="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                hasRetailSellersLicense.invalid &&
                (hasRetailSellersLicense.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="hasRetailSellersLicense.value === 'Yes'"
          >
            <label>Retail License Number</label>
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(retailSellersLicense, form)"
              #retailSellersLicense="ngModel"
              name="retailSellersLicense"
              maxlength="20"
              required
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                retailSellersLicense.invalid &&
                (retailSellersLicense.touched || form.submitted)
              "
              >Retail license number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Is the business currently involved in any litigation or other legal
            claim?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="businessLitigation"
                ngModel
                #currentLegalClaim="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="businessLitigation"
                ngModel
                #currentLegalClaim="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                currentLegalClaim.invalid &&
                (currentLegalClaim.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Has the business, any parent company, subsidiary or affiliated
            entity ever filed bankruptcy?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="businessBankruptcy"
                ngModel
                #businessFiledBankruptcy="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="businessBankruptcy"
                ngModel
                #businessFiledBankruptcy="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                businessFiledBankruptcy.invalid &&
                (businessFiledBankruptcy.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="businessFiledBankruptcy.control.value === 'Yes'"
          >
            <label
              >If yes, list business name, date of filing and explanation of
              circumstances</label
            >
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessFiledBankruptcyResponse, form)"
              #businessFiledBankruptcyResponse="ngModel"
              name="bankruptcy"
              maxlength="400"
              required
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                businessFiledBankruptcyResponse.invalid &&
                (businessFiledBankruptcyResponse.touched || form.submitted)
              "
              >Explanation of circumstances is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>
            Are any taxes currently past due by the business or any
            principal?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="businessPastDueTax"
                ngModel
                #unpaidBills="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="businessPastDueTax"
                ngModel
                #unpaidBills="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                unpaidBills.invalid && (unpaidBills.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Is the business or any principal contingently liable as a guarantor
            or endorser?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="businessLiableAsGuarantor"
                ngModel
                #liableGuarantor="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="businessLiableAsGuarantor"
                ngModel
                #liableGuarantor="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                liableGuarantor.invalid &&
                (liableGuarantor.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>
            Has any owner, officer, partner, member or manager been convicted of
            a felony?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="conviction"
                ngModel
                #felonyCharge="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="conviction"
                ngModel
                #felonyCharge="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                felonyCharge.invalid && (felonyCharge.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div class="form-group" *ngIf="felonyCharge.control.value === 'Yes'">
            <label
              >If yes, list business name, date of filing and explanation of
              circumstances</label
            >
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(felonyChargeResponse, form)"
              #felonyChargeResponse="ngModel"
              name="convictionExplanation"
              maxlength="400"
              required
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                felonyChargeResponse.invalid &&
                (felonyChargeResponse.touched || form.submitted)
              "
              >Explanation of circumstances is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Has any owner, officer, partner, member or manager ever filed
            bankruptcy?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="ownerBankruptcy"
                ngModel
                #individualBankruptcy="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="ownerBankruptcy"
                ngModel
                #individualBankruptcy="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                individualBankruptcy.invalid &&
                (individualBankruptcy.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label> Does the business sell products via the internet?</label>
          <br />
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="sellViaInternet"
                ngModel
                #sellViaInternet="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="sellViaInternet"
                ngModel
                #sellViaInternet="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                sellViaInternet.invalid &&
                (sellViaInternet.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="sellViaInternet.control.value === 'Yes'"
          >
            <label>If yes, provide website</label>
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(businessWebsiteResponse, form)"
              #businessWebsiteResponse="ngModel"
              name="sellingWebsite"
              maxlength="400"
              required
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                businessWebsiteResponse.invalid &&
                (businessWebsiteResponse.touched || form.submitted)
              "
              >Website name is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Is the business regulated by a U.S. federal regulatory agency (e.g.
            SEC or OCC)?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="federallyRegulated"
                ngModel
                #secOrOcc="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="federallyRegulated"
                ngModel
                #secOrOcc="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="secOrOcc.invalid && (secOrOcc.touched || form.submitted)"
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>
            Do any owners holding 25% or greater equity reside outside the
            United States?</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="ownerResidesOutsideUS"
                ngModel
                #equityOutsideCountry="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="ownerResidesOutsideUS"
                ngModel
                #equityOutsideCountry="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                equityOutsideCountry.invalid &&
                (equityOutsideCountry.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label
            >Have you partnered with Priority One previously?</label
          ><br>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="previousPartner"
                ngModel
                #prevPartnered="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="previousPartner"
                ngModel
                #prevPartnered="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="prevPartnered.invalid && (prevPartnered.touched || form.submitted)"
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>Are you a Kenect texting platform dealer?</label><br>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input mt-3 mb-3" type="radio" name="kenectDealer" ngModel #kenectDealer="ngModel"
                value="Yes" required />
              Yes</label>
            <label class="form-check-label">
              <input class="form-check-input mt-3 mb-3 ml-3" type="radio" name="kenectDealer" ngModel
                #kenectDealer="ngModel" value="No" required />
              No</label>
            <span *ngIf="kenectDealer.invalid && (kenectDealer.touched || form.submitted)" class="text-danger ml-2">
              Required.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <div class="form-group">
            <label>
              How are you currently handling your F&I?</label
            >
            <!-- <div class="form-check form-check-inline" style="width: 250px;"> -->
              <select
                class="custom-select"
                ngModel
                name="fiSelection"
                #fiSelection="ngModel"
                required
                style="width: 250px;"
                [ngClass]="setClasses(fiSelection, form)">
                <option></option>
                <option *ngFor="let value of fiChoicesArr">
                  {{ value }}
                </option>
              </select>
              <div>
                <small
                  class="text-danger"
                  *ngIf="
                  fiSelection.invalid && (fiSelection.touched || form.submitted)
                  "
                  >F&I selection is required.</small
                >
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>
            What dealer management tool does your dealership use?</label
          >
          <!-- <div class="form-check form-check-inline" > -->
            <select
              class="custom-select"
              ngModel
              name="dmsTool"
              #dmsTool="ngModel"
              required
              style="width: 250px;"
              [ngClass]="setClasses(dmsTool, form)"
            >
              <option></option>
              <option
                *ngFor="let value of dmsArr"
              >
                {{ value }}
              </option>
            </select>
            <div>
              <small
                class="text-danger"
                *ngIf="
                  dmsTool.invalid && (dmsTool.touched || form.submitted)
                "
                >Dealer management tool is required.</small
              >
            </div>
          <!-- </div> -->
        </div>
        <div class="col-12">
          <label
            >Are you or any beneficial owners with authority to act on behalf of
            the company, a "non-U.S. Politically Exposed Person" as the
            following describes? A non-U.S. political figure is defined as a
            current or former senior official in the executive, legislative,
            administrative, military, or judicial branches of a foreign
            government (whether elected or not), a senior official of a major
            political party of a non-U.S. country, a senior executive of a
            non-U.S. government owned corporation, any corporation or business
            that has been formed by the benefit of a Politically Exposed Person,
            an immediate family member of a Politically Exposed Person, any
            person publicly known, or known by the bank to be a close personal
            or professional associate of a Politically Exposed Person.</label
          >
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="politicallyExposed"
                ngModel
                #politicallyExposedPerson="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="politicallyExposed"
                ngModel
                #politicallyExposedPerson="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                politicallyExposedPerson.invalid &&
                (politicallyExposedPerson.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
        </div>
        <div class="col-12">
          <div
            class="row"
            *ngIf="politicallyExposedPerson.control.value === 'Yes'"
          >
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label>Political official's name</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(politicalOfficalName, form)"
                  #politicalOfficalName="ngModel"
                  name="politicalOfficalName"
                  maxlength="40"
                  required
                />
                <small
                  class="text-danger"
                  *ngIf="
                    politicalOfficalName.invalid &&
                    (politicalOfficalName.touched || form.submitted)
                  "
                  >Name is required.</small
                >
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label>Current or former position</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(currentOrFormerPosition, form)"
                  #currentOrFormerPosition="ngModel"
                  name="politicalOfficialPosition"
                  maxlength="40"
                  required
                />
                <small
                  class="text-danger"
                  *ngIf="
                    currentOrFormerPosition.invalid &&
                    (currentOrFormerPosition.touched || form.submitted)
                  "
                  >Position is required.</small
                >
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="form-group">
                <label>Relationship to politically official</label>
                <input
                  class="form-control"
                  type="text"
                  ngModel
                  [ngClass]="setClasses(relationshipToPoliticalPerson, form)"
                  #relationshipToPoliticalPerson="ngModel"
                  name="relationshipToPoliticalOffical"
                  maxlength="40"
                  required
                />
                <small
                  class="text-danger"
                  *ngIf="
                    relationshipToPoliticalPerson.invalid &&
                    (relationshipToPoliticalPerson.touched || form.submitted)
                  "
                  >Relationship is required.</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of management row -->
      <!-- Your Business End -->
      <principals
        ngModel
        [form]="form"
        [ngModelOptions]="{ standalone: true }"
      ></principals>
      <!-- Start of Trade Ref -->
      <h1 class="display-4">Trade References</h1>
      <label>Check all that apply</label>
      <div class="row mb-4" ngModelGroup="tradeReferences">
        <div
          class="col-6 col-lg-4 col-md-4 mb-4"
          *ngFor="let tradeReference of traderReferences"
        >
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              [name]="'tradeReference' + tradeReference"
              #tradeType="ngModel"
            />
            {{ tradeReference }}</label
          >
        </div>
        <div class="col-6 col-lg-4 col-md-4 mb-4">
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              name="tradeReferenceOtherChecked"
              #tradeReferenceOtherChecked="ngModel"
              value="other"
            />
            Other</label
          >
        </div>
        <div class="col-12 p-0">
          <div class="col-12 col-md-6" *ngIf="tradeReferenceOtherChecked.value">
            <label>Other</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(tradeReferenceOther, form)"
              #tradeReferenceOther="ngModel"
              name="tradeReferenceOther"
              maxlength="40"
              required
            />
            <small
              class="text-danger"
              *ngIf="
                tradeReferenceOther.invalid &&
                (tradeReferenceOther.touched || form.submitted)
              "
              >Other is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end trade ref -->
      <!-- Start of WHOLESALE INFORMATION: FLOORING SOURCES -->
      <h1 class="display-4">Wholesale Information: Flooring Sources</h1>
      <div class="mb-4 row">
        <div
          class="mb-3"
          *ngFor="let item of [].constructor(2); let index = index"
        >
          <div [ngModelGroup]="'wholesaleInfo' + (index + 1)">
            <div class="row col-12">
              <hr class="w-100 mb-4" *ngIf="index >= 1" />
              <div class="form-group col-12">
                <label>Bank</label>
                <searchable-dropdown
                  ngModel
                  [form]="form"
                  #bank="ngModel"
                  name="bank"
                  maxlength="100"
                  [ngClass]="setClasses(bank, form)"
                  [list]="list"
                ></searchable-dropdown>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label>Contact</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(contact, form)"
                    #contact="ngModel"
                    name="contact"
                    maxlength="150"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                      contact.invalid && (contact.touched || form.submitted)
                    "
                    >A valid website is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label>Phone</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(phone, form)"
                    #phone="ngModel"
                    name="phone"
                    minlength="10"
                    mask="(000) 000-0000"
                  />
                  <small
                    class="text-danger"
                    *ngIf="phone.invalid && (phone.touched || form?.submitted)"
                    >A valid 10 digit phone number is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label>Fax</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(fax, form)"
                    #fax="ngModel"
                    name="fax"
                    minlength="10"
                    mask="(000) 000-0000"
                  />
                  <small
                    class="text-danger"
                    *ngIf="fax.invalid && (fax.touched || form.submitted)"
                    >A valid 10 digit fax number is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label>Email Address</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(email, form)"
                    #email="ngModel"
                    name="email"
                    maxlength="40"
                    pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
                  />
                  <small
                    class="text-danger"
                    *ngIf="email.invalid && (email.touched || form.submitted)"
                    >A valid email address is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <div class="form-group">
                  <label>Zip</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(zip, form)"
                    #zip="ngModel"
                    name="zip"
                    mask="00000"
                    (change)="
                      getAddress(
                        $event.target.value,
                        'wholesaleInfo' + (index + 1),
                        'city',
                        'state'
                      )
                    "
                    maxlength="5"
                    minlength="5"
                  />
                  <small
                    class="text-danger"
                    *ngIf="zip.invalid && (zip.touched || form.submitted)"
                    >A valid zip is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label>City</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(city, form)"
                    #city="ngModel"
                    name="city"
                    maxlength="40"
                  />
                  <small
                    class="text-danger"
                    *ngIf="city.invalid && (city.touched || form.submitted)"
                    >City is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-2">
                <div class="form-group">
                  <label>State</label>
                  <select
                    class="custom-select"
                    ngModel
                    name="state"
                    [ngClass]="setClasses(state, form)"
                    #state="ngModel"
                  >
                    <option></option>
                    <option
                      *ngFor="let value of states$"
                      [value]="value.StateAbbreviation"
                    >
                      {{ value.StateAbbreviation }}
                    </option>
                  </select>
                  <div>
                    <small
                      class="text-danger"
                      *ngIf="state.invalid && (state.touched || form.submitted)"
                      >State is required.</small
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-5">
                <div class="form-group">
                  <label>Street Address</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(streetAddress, form)"
                    #streetAddress="ngModel"
                    name="address"
                    maxlength="60"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                      streetAddress.invalid &&
                      (streetAddress.touched || form.submitted)
                    "
                    >Street address is required.</small
                  >
                </div>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <div class="form-group">
                  <label>Credit Limit</label>
                  <div class="placeholder" data-placeholder="$"></div>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(creditLimit, form)"
                    #creditLimit="ngModel"
                    name="creditLimit"
                    maxlength="10"
                    mask="separator.0"
                    thousandSeparator=","
                    [dropSpecialCharacters]="true"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                    creditLimit.invalid &&
                      (creditLimit.touched || form.submitted)
                    "
                    >Credit Limit is required.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-lg-4 col-md-6"
          *ngIf="form.value?.wholesaleInfo1 && !form.value?.wholesaleInfo2"
        >
          <div class="form-group">
            <label>Add Another Bank</label>
            <select
              class="custom-select"
              [ngClass]="setClasses(secondSelectBank)"
              #secondSelectBank="ngModel"
              ngModel
              name="wholesaleinfo2"
              #bankElement
              (blur)="
                selectedItem({
                  formGroupName: 'wholesaleInfo2',
                  value: secondSelectBank.value,
                  controlName: 'bank'
                })
              "
            >
              <option></option>
              <option
                *ngFor="let item of list; let i = index"
                [disabled]="!isCategoryAvailable(item)"
                [ngValue]="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- end of wholesale -->
      <!-- START OF FINACIAL -->
      <h1 class="display-4 mt-3">Financial Information</h1>
      <div class="row mb-4" ngModelGroup="financialInfo">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Federal Tax ID (aka FEIN)</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(fedTaxId, form)"
              #fedTaxId="ngModel"
              name="federalTaxId"
              mask="00-0000000"
              required
            />
            <small
              class="text-danger"
              *ngIf="fedTaxId.invalid && (fedTaxId.touched || form.submitted)"
              >A valid 9 digit FEIN is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Percent of annual deliveries financed</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(percentFinanced, form)"
              #percentFinanced="ngModel"
              name="annualDeliveriesFinanced"
              mask="percent.2"
              suffix="%"
              pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
              [dropSpecialCharacters]="true"
            />
            <small
              class="text-danger"
              *ngIf="
                percentFinanced.invalid &&
                (percentFinanced.touched || form.submitted)
              "
              >Number must be between 0-100.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Average Sale Price of Unit (forecasting)</label>
            <div class="placeholder" data-placeholder="$">
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(avgSalePriceUnit, form)"
                #avgSalePriceUnit="ngModel"
                name="averageUnitSize"
                maxlength="20"
                mask="separator.2"
                thousandSeparator=","
              />
            </div>
            <small
              class="text-danger"
              *ngIf="
                avgSalePriceUnit.invalid &&
                (avgSalePriceUnit.touched || form.submitted)
              "
              >Atleast 0-10 digits required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Current Inventory</label>
            <div class="placeholder" data-placeholder="$">
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(currentInventory, form)"
                #currentInventory="ngModel"
                name="currentInventoryAmountVolume"
                maxlength="20"
                mask="separator.2"
                thousandSeparator=","
              />
            </div>
            <small
              class="text-danger"
              *ngIf="
                currentInventory.invalid &&
                (currentInventory.touched || form.submitted)
              "
              >Atleast 0-20 digits required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label> Average Sales per Month (units)</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(avgSalesMonthUnit, form)"
              #avgSalesMonthUnit="ngModel"
              name="averageSalesPerMonthUnits"
              maxlength="20"
              mask="separator.2"
              thousandSeparator=","
            />
            <small
              class="text-danger"
              *ngIf="
                avgSalesMonthUnit.invalid &&
                (avgSalesMonthUnit.touched || form.submitted)
              "
              >Atleast 0-20 digits required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Average Sales per Month</label>
            <div class="placeholder" data-placeholder="$">
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(avgSalesMonth, form)"
                #avgSalesMonth="ngModel"
                name="averageSalesPerMonthVolume"
                maxlength="20"
                mask="separator.2"
                thousandSeparator=","
              />
            </div>
            <small
              class="text-danger"
              *ngIf="
                avgSalesMonth.invalid &&
                (avgSalesMonth.touched || form.submitted)
              "
              >Atleast 0-20 digits required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of finacial info -->
      <!-- start of bank of deposit -->
      <h1 class="display-4">Bank of Deposit</h1>
      <div class="row mb-4" ngModelGroup="bankOfDeposit">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Bank Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankName, form)"
              #bankName="ngModel"
              name="name"
              maxlength="40"
            />
            <small
              class="text-danger"
              *ngIf="bankName.invalid && (bankName.touched || form.submitted)"
              >Atleast 0-40 characters required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Account Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountNumber, form)"
              #accountNumber="ngModel"
              name="account"
              maxlength="20"
              mask="0*"
            />
            <small
              class="text-danger"
              *ngIf="
                accountNumber.invalid &&
                (accountNumber.touched || form.submitted)
              "
              >Atleast 0-20 characters is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Bank Routing Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(accountRouting, form)"
              #accountRouting="ngModel"
              name="accountRouting"
              maxlength="9"
              mask="0*"
            />
            <small
              class="text-danger"
              *ngIf="
                accountRouting.invalid &&
                (accountRouting.touched || form.submitted)
              "
              >A valid 9 digit routing number is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Contact Name</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankContact, form)"
              #bankContact="ngModel"
              name="contact"
              maxlength="100"
            />
            <small
              class="text-danger"
              *ngIf="
                bankContact.invalid && (bankContact.touched || form.submitted)
              "
              >Name between 0-100 characters is required.</small
            >
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(bankPhoneNumber, form)"
              #bankPhoneNumber="ngModel"
              name="phone"
              minlength="10"
              mask="(000) 000-0000"
            />
            <small
              class="text-danger"
              *ngIf="
                bankPhoneNumber.invalid &&
                (bankPhoneNumber.touched || form.submitted)
              "
              >A valid 10 digit phone is required.</small
            >
          </div>
        </div>
      </div>
      <lenders
        ngModel
        [ngModelOptions]="{ standalone: true }"
        [form]="form"
      ></lenders>
      <manufacturer
        [form]="form"
        ngModel
        [ngModelOptions]="{ standalone: true }"
      ></manufacturer>
      <!-- start of licenses -->
      <div *ngIf="physicalState.value === 'PA'" class="mb-4">
        <h1 class="display-4">Licenses</h1>
        <div class="row mb-2" ngModelGroup="licenses">
          <div class="col-12 col-lg-6 col-md-6 mb-3">
            <label class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                ngModel
                name="PAInstallmentSellerLicense"
                #licensesPa="ngModel"
              />
              I have an installment seller license
              <span
                *ngIf="
                  licensesPa.invalid && (licensesPa.touched || form.submitted)
                "
                class="text-danger ml-2"
              >
                Required.
              </span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="physicalState.value === 'TX'">
        <h1 class="display-4">Licenses</h1>
        <div class="row mb-4" ngModelGroup="licenses">
          <div
            class="col-12 col-lg-6 col-md-6"
            *ngFor="let text of licensesTypesTX; let checkboxIndex = index"
          >
            <label class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                ngModel
                [name]="text"
                #p1Products="ngModel"
              />
              {{ text }}
              <span
                *ngIf="
                  p1Products.invalid && (p1Products.touched || form.submitted)
                "
                class="text-danger ml-2"
              >
                Required.
              </span>
            </label>
          </div>
        </div>
      </div>
      <div
        class="alert alert-primary col-12"
        role="alert"
        *ngIf="anyFormMembersTrue('licenses')"
      >
        <i class="fas fa-info-circle fa-lg mr-2"></i> You will need to provide a
        copy to Priority One.
      </div>
      <!-- end of licenses -->
      <!-- Start of Tax Info -->
      <h1 class="display-4">Tax Information</h1>
      <h4 class="lead text-center mt-3 mb-3">
        Do you collect tax on the following
      </h4>
      <div class="row mb-4" ngModelGroup="taxInfo">
        <div
          *ngFor="let value of p1TaxInformation.label; let index = index"
          class="col-12 col-md-6 col-lg-3"
        >
          <div class="form-group">
            <label>{{ value }}</label>
            <select
              class="custom-select"
              ngModel
              [ngClass]="setClasses(taxInformation, form)"
              [name]="value + 'Tax'"
              #taxInformation="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of p1TaxInformation.taxInfo"
                [ngValue]="value"
              >
                {{ value }}
              </option>
            </select>
            <div class="row">
              <div
                class="col mt-3 mb-3"
                *ngIf="taxInformation.value === 'Yes, flat tax rate'"
              >
                <div class="form-group">
                  <label>Flat Tax Rate</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(taxRateResponse, form)"
                    #taxRateResponse="ngModel"
                    [name]="value + 'TaxRate'"
                    required
                    mask="percent.2"
                    suffix="%"
                    pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
                    [dropSpecialCharacters]="true"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                      taxRateResponse.invalid &&
                      (taxRateResponse.touched || form.submitted)
                    "
                    >A valid number between 0-100 is required.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label>Extended Service Contract</label>
            <select
              class="custom-select"
              ngModel
              [ngClass]="setClasses(extendedWarrantyTax, form)"
              name="extendedWarrantyTax"
              #extendedWarrantyTax="ngModel"
            >
              <option></option>
              <option
                *ngFor="let value of p1TaxInformation.taxInfo"
                [ngValue]="value"
              >
                {{ value }}
              </option>
            </select>
            <div class="row">
              <div
                class="col mt-3 mb-3"
                *ngIf="extendedWarrantyTax.value === 'Yes, flat tax rate'"
              >
                <div class="form-group">
                  <label>Flat Tax Rate</label>
                  <input
                    class="form-control"
                    type="text"
                    ngModel
                    [ngClass]="setClasses(extendedWarrantyTaxRate, form)"
                    #extendedWarrantyTaxRate="ngModel"
                    name="extendedWarrantyTaxRate"
                    required
                    mask="percent.2"
                    suffix="%"
                    pattern="^\d{1,2}(\.\d+)?$|^100(\.0+)?$"
                    [dropSpecialCharacters]="true"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                      extendedWarrantyTaxRate.invalid &&
                      (extendedWarrantyTaxRate.touched || form.submitted)
                    "
                    >A valid number between 0-100 is required.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of tax info -->
      <additional-fees
        ngModel
        [ngModelOptions]="{ standalone: true }"
        [form]="form"
      ></additional-fees>
      <h1 class="display-4">Service Contracts</h1>
      <label>Check all that apply</label>
      <div class="row mb-4" ngModelGroup="serviceContracts">
        <div
          class="col-6 col-lg-4 col-md-4 mb-4"
          *ngFor="let p1ServiceContract of p1ServiceContracts"
        >
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              [name]="'extendedServiceContract' + p1ServiceContract"
              #p1Product="ngModel"
              [value]="p1ServiceContract"
            />
            {{ p1ServiceContract }}</label
          >
        </div>
        <div class="col-6 col-lg-4 col-md-4 mb-4">
          <label class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              ngModel
              name="extendedServiceContractOtherChecked"
              #serviceOther="ngModel"
              value="other"
            />
            Other</label
          >
        </div>
        <div class="col-12 mb-4">
          <div class="row">
            <div class="col-12 col-md-6" *ngIf="serviceOther.value">
              <label>Other</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(serviceOtherResponse, form)"
                #serviceOtherResponse="ngModel"
                name="extendedServiceContractOther"
                maxlength="400"
                required
              />
              <small
                class="text-danger"
                *ngIf="
                  serviceOtherResponse.invalid &&
                  (serviceOtherResponse.touched || form.submitted)
                "
                >Other is required.</small
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3">
          <label>Do you perform your own repairs?</label>
          <br />
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="performOwnRepairs"
                ngModel
                #serviceRepair="ngModel"
                value="Yes"
                required
              />
              Yes</label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="performOwnRepairs"
                ngModel
                #serviceRepair="ngModel"
                value="No"
                required
              />
              No</label
            >
            <span
              *ngIf="
                serviceRepair.invalid &&
                (serviceRepair.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div class="form-group" *ngIf="serviceRepair.control.value === 'Yes'">
            <label>If yes, what is your hourly retail repair labor rate?</label>
            <textarea
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(serviceRepairResponse, form)"
              #serviceRepairResponse="ngModel"
              name="hourlyRepairLaborRate"
              maxlength="40"
              required
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                serviceRepairResponse.invalid &&
                (serviceRepairResponse.touched || form.submitted)
              "
              >Repair rate is required.</small
            >
          </div>
        </div>
      </div>
      <!-- end of Services contracts fees -->
      <!-- start of contact information -->
      <h1 class="display-4">Contact Information</h1>
      <div *ngIf="fieldNames.length > 0">
        <div
          class="row mb-3"
          [ngModelGroup]="name"
          *ngFor="let name of fieldNames; let index = index"
        >
          <hr *ngIf="index > 0" class="w-100" />
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Name</label>
              <input
                class="form-control"
                type="text"
                ngModel
                #contactName="ngModel"
                name="name"
                maxlength="100"
                [ngClass]="setClasses(contactName, form)"
                required
              />
              <small
                class="text-danger"
                *ngIf="
                  contactName.invalid && (contactName.touched || form.submitted)
                "
                >A valid name is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Title</label>
              <searchable-dropdown
                ngModel
                #contactTitle="ngModel"
                name="title"
                maxlength="100"
                [ngClass]="setClasses(contactTitle, form)"
                required
                [list]="titleArr"
              ></searchable-dropdown>
              <small
                class="text-danger"
                *ngIf="
                  contactTitle.invalid &&
                  (contactTitle.touched || form.submitted)
                "
                >The title of the staff member is required.</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <label>Phone Number</label>
            <div class="input-group">
              <input
                style="width: 60%!important;"
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(contactPhone, form)"
                #contactPhone="ngModel"
                name="phone"
                minlength="10"
                mask="(000) 000-0000"
                [required]="!contactEmail.value"
              />
              <select
                style="width: 40%!important;"
                [ngModel]=""
                #phoneType="ngModel"
                name="phoneType"
                [ngClass]="setClasses(phoneType, form)"
                [required]="contactPhone.value?.length === 10 && contactPhone.valid && contactPhone.touched"
                (ngModelChange)="setSmsOptOut(name, $event);"
              >
                <option>Cell - SMS Ok</option>
                <option>Cell - No SMS</option>
                <option>Landline</option>
              </select>
            </div>
            <small
              class="text-danger"
              *ngIf="
                contactPhone.invalid && (contactPhone.touched || form.submitted)
              "
              >A valid 10 digit phone number is required.</small
            >
            <small
              class="text-danger"
              *ngIf="phoneType.invalid && (phoneType.touched || form.submitted)"
              >You must select a phone type.</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label>Email</label>
              <input
                class="form-control"
                type="text"
                ngModel
                [ngClass]="setClasses(contactEmail, form)"
                #contactEmail="ngModel"
                name="email"
                maxlength="100"
                pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
                [required]="!contactPhone.value"
              />
              <small
                class="text-danger"
                *ngIf="
                  contactEmail.invalid &&
                  (contactEmail.touched || form.submitted)
                "
                >A valid email is required.</small
              >
            </div>
            <div class="row mb-3">
              <div class="col-12 text-right">
                <button
                  *ngIf="fieldNames.length > 0"
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="removeFields(name)"
                >
                  Remove Staff Member
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-4">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          (click)="addNames()"
          *ngIf="fieldNames.length < 10"
        >
          Add Staff Members
        </button>
      </div>
      <div class="row mb-4" ngModelGroup="contactInfo">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <div class="form-group">
            <label>eLoan Package Notification Email </label>
            <input
              class="form-control"
              type="text"
              ngModel
              [ngClass]="setClasses(eLoanEmail, form)"
              #eLoanEmail="ngModel"
              name="eLoanEmail"
              maxlength="60"
              required
              pattern="\b[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
            />
            <small
              class="text-danger"
              *ngIf="
                eLoanEmail.invalid && (eLoanEmail.touched || form.submitted)
              "
              >A valid email is required.</small
            >
          </div>
        </div>
      </div>
      <additional-locations
        ngModel
        [form]="form"
        [ngModelOptions]="{ standalone: true }"
      ></additional-locations>
      <h1 class="display-4">Privacy Notice Service</h1>
      <div class="row" ngModelGroup="privacyInfo">
        <div
          class="col-12 mb-1"
        >
          <label> Does your dealership already have an established 
            procedure for providing consumers with a Privacy Notice as required 
            by the Gramm-Leach-Bliley Act? </label>
          <br />
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="hasPrivacyNoticePolicy"
                ngModel
                #hasPrivacyNoticePolicy="ngModel"
                value="Yes"
                required
                useP1PrivacyNotice="''"
              />
              Yes </label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="hasPrivacyNoticePolicy"
                ngModel
                #hasPrivacyNoticePolicy="ngModel"
                value="No"
                required
              />
              No </label
            >
            <span
              *ngIf="
                hasPrivacyNoticePolicy.invalid &&
                (hasPrivacyNoticePolicy.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="!!hasPrivacyNoticePolicy.value"
          >
          <span 
            *ngIf="hasPrivacyNoticePolicy.value === 'Yes'" class="text-success"
            >Given you already provide a privacy notice to the consumer we will deactivate the Priority One Privacy Notice service for your dealership.</span>
          </div>
        </div>
        <div
          class="col-12 mb-3"
          *ngIf="hasPrivacyNoticePolicy.value === 'No'"
        >
          <label> Given you do not currently provide a privacy notice to the consumer we need to ensure the Priority One Privacy Notice service will work for 
            you. Do you only share consumer's personal information for the purpose of processing a transaction that the consumer has requested, such as 
            applying for financing? In other words, you don't provide the consumer's personal information to any unaffiliated (non-owned) third party for any 
            purpose other than processing a transaction requested by the consumer. Is that correct? </label>
          <br />
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3"
                type="radio"
                name="useP1PrivacyNotice"
                ngModel
                #useP1PrivacyNotice="ngModel"
                value="Yes"
                required
              />
              Yes, that is correct </label
            >
            <label class="form-check-label">
              <input
                class="form-check-input mt-3 mb-3 ml-3"
                type="radio"
                name="useP1PrivacyNotice"
                ngModel
                #useP1PrivacyNotice="ngModel"
                value="No"
                required
              />
              No </label
            >
            <span
              *ngIf="
                useP1PrivacyNotice.invalid &&
                (useP1PrivacyNotice.touched || form.submitted)
              "
              class="text-danger ml-2"
            >
              Required.
            </span>
          </div>
          <div
            class="form-group"
            *ngIf="!!useP1PrivacyNotice.value"
          >
          <span 
            *ngIf="useP1PrivacyNotice.value === 'Yes'" class="text-success"
            >The Priority One Privacy Notice service will work for your dealership. With that service, a Privacy Notice will be included 
            in the loan closing documents.  The Privacy Notice will be in the name of your dealership and directed to the consumer.</span>
            <span
            *ngIf="useP1PrivacyNotice.value === 'No'" class="text-danger"
            >Under those circumstances the Priority One Privacy Notice service will not work to meet your dealership's federal regulatory requirements.
            <br/><br/>
            Once your dealership is operational with Priority One you will have access to the Federal Regulation Compliance Guide on the Dealer Resource 
            Center (DRC).  The Compliance Guide will provide information that will help guide you toward meeting your compliance requirements.</span>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <button
          type="submit"
          class="btn btn-success btn-lg"
          [style.background-color]="form.invalid ? 'gray' : '#5cb85c'"
          [style.border]="form.invalid ? 'gray' : '#5cb85c'"
        >
          Submit<i class="far fa-spinner fa-pulse" *ngIf="waiting"></i>
        </button>
      </div>
    </form>
  </div>
</div>
