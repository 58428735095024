import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.css']
})
export class VersionHistoryComponent implements OnInit {

  versionData;
  displayInfo = false;

  constructor(private http: HttpClient, private route: ActivatedRoute) { 
    this.route.queryParamMap
    .subscribe((x: any) => {
      if (typeof x.params.bigfoot != 'undefined') {
        if (x.params.bigfoot == "true") localStorage.setItem("bigfoot", "true");
        else localStorage.removeItem("bigfoot");
      }
    });

    if (localStorage.getItem("bigfoot") == "true") this.displayInfo = true;

    this.getVersionData().subscribe(
      (data:any) => this.versionData = data.versionData
    );
  }

  ngOnInit(): void {
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  getVersionData() {
    return this.http.get(`assets/data/versions.json`);
  }

}
