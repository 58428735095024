<input
  id="typeahead-focus"
  type="text"
  class="form-control"
  [(ngModel)]="modelValue"
  [ngbTypeahead]="search"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  #instance="ngbTypeahead"
  (input)="onChange($event.target.value)"
  (blur)="onTouched(); onChange($event.target.value)"
  [ngClass]="setClasses(controlDir, form)"
/>
