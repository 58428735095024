import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() text: string = '';
  isChecked: any = false;

  constructor() {}
  onChange: (_: any) => void = (_: any) => {};
  onTouched: () => void = () => {};

  updateChanges() {
    this.isChecked = this.isChecked ? true : null;
    this.onChange(this.isChecked);
  }

  writeValue(isChecked: boolean): void {
    this.isChecked = isChecked;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
