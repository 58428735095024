import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
})
export class ErrorComponent {
  statusText;
  constructor(route: ActivatedRoute){
    let statusCode = (route.snapshot.paramMap.get('statusCode'))
    if(statusCode === '429'){
      this.statusText = "Suspicious activity detected from your network."
    }
  }
  

}
