<div class="ml-3">
  <input
    class="form-check-input"
    type="checkbox"
    [(ngModel)]="isChecked"
    (ngModelChange)="updateChanges()"
    required
  />
  <label class="form-check-label">I agree</label>
  <p>
    {{ text }}
  </p>
</div>
